export class NotificationModel {
  id: string;
  title: string;
  message: string;
  read: boolean;
  createdDate: string;
  serviceName: string;
  url: string;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.title = object.title;
      this.message = object.message;
      this.read = object.read;
      this.createdDate = object.createdDate;
      this.serviceName = object.serviceName;
      this.url = object.url;
    }
  }
}
