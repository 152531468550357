import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-notification-toastr',
  templateUrl: './notification-toastr.component.html',
  styleUrls: ['./notification-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
        transform: 'translateX(-30px)'
      })),
      transition('inactive => active', animate('400ms ease-out', style({
        opacity: 1,
        transform: 'translateX(0)'
      }))),
      transition('active => removed', animate('400ms ease-out', style({
        opacity: 0,
        transform: 'translateX(-30px)'
      }))),
    ]),
  ]
})
export class NotificationToastrComponent extends Toast {

  @Input() origin: string;

  @Output() notificationClicked = new EventEmitter();

  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  clickOnNotification() {
    this.notificationClicked.emit();
  }
}
