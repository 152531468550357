import {ActivitySheetStatusEnum} from "./activity-sheet.model";

export class ActivitySheetAdvancedSearchModel{
  purchaseOrderNumber: string;
  buyerCompanyName: string;
  supplierCompanyName: string;
  providerName: string;
  projectName: string;
  period: string;
  status: ActivitySheetStatusEnum[];
}
