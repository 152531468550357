import {animate, animation, group, stagger, style} from '@angular/animations';

export const slideInAnimation = animation([
  style({opacity: 0, transform: 'translateY({{from}})'}),
  animate('{{timings}}', style({opacity: '1', transform: 'translateY(0)'}))
], {params: {from: '20px', timings: '.2s ease-out'}});

export const slideOutAnimation = animation([
  animate('{{timings}}', style({opacity: '0', transform: 'translateY({{to}})'}))
], {params: {to: '20px', timings: '.2s ease-in'}});

export const fadeInAnimation = animation([
  style({opacity: 0}),
  animate('{{timings}}', style({opacity: 1}))
], {params: {timings: '.2s ease-out'}});

export const fadeOutAnimation = animation([
  animate('{{timings}}', style({opacity: 0}))
], {params: {timings: '.2s ease-in'}});

export const fadeInHeightAnimation = animation([
  style({opacity: 0, height: '{{heightInitial}}', marginTop: 0, marginBottom: 0}),
  group([
    animate('{{opacityTimings}}', style({opacity: 1})),
    animate('{{heightTimings}}', style({height: '*', marginTop: '*', marginBottom: '*'}))
  ])
], {params: {opacityTimings: '.1s ease-in', heightTimings: '.3s ease-out', heightInitial: 0}});

export const fadeOutHeightAnimation = animation([
  group([
    animate('{{opacityTimings}}', style({opacity: 0})),
    animate('{{heightTimings}}', style({height: 0, marginTop: 0, marginBottom: 0}))
  ])
], {params: {opacityTimings: '.1s ease-in', heightTimings: '.3s ease-out'}});

export const scaleInAnimation = animation([
  style({opacity: 0, transform: 'scale({{from}})'}),
  animate('{{timings}}', style({opacity: 1, transform: 'scale(1)'}))
], {params: {from: '0.9', timings: '.2s ease-out'}});

export const scaleOutAnimation = animation([
  animate('{{timings}}', style({opacity: 0, transform: 'scale({{to}})'}))
], {params: {to: '0.9', timings: '.2s ease-in'}});

// Stagger Animation

export const scaleIn50StaggerAnimation = animation([
  style({opacity: 0, transform: 'scale({{from}})'}),
  stagger(50, [
    animate('{{timings}}', style({opacity: '1', transform: 'scale(1)'}))
  ])
], {params: {from: '0.9', timings: '.2s ease-out'}});


export const slideIn50StaggerAnimation = animation([
  style({opacity: 0, transform: 'translateY({{from}})'}),
  stagger(50, [
    animate('{{timings}}', style({opacity: '1', transform: 'translateY(0)'}))
  ])
], {params: {from: '20px', timings: '.2s ease-out'}});


export const slideIn20StaggerAnimation = animation([
  style({opacity: 0, transform: 'translateY({{from}})'}),
  stagger(20, [
    animate('{{timings}}', style({opacity: '1', transform: 'translateY(0)'}))
  ])
], {params: {from: '20px', timings: '.2s ease-out'}});
