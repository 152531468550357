import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-kpi-progress-bar',
  templateUrl: './kpi-progress-bar.component.html',
  styleUrls: ['./kpi-progress-bar.component.scss']
})
export class KpiProgressBarComponent implements OnInit {

  @Input() title: string;
  @Input() valueText: string;
  @Input() value: number;
  @Input() max: number;
  @Input() colorBar: string;

  constructor() {
  }

  ngOnInit() {
  }

}
