import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {

  transform(value: string | number, args: string): string | number {
    return (value) ? value : args;
  }

}
