import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'statusReadable'
})
export class StatusPipe implements PipeTransform {
  transform(data: string): string {
    if (data === 'T_M') {
      return 'T & M';
    } else {
      return (data) ? data.replace(/_/g, ' ').replace(/\./g, ' ') : data;
    }
  }
}
