import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {regex} from '../regex/form.regex';

@Directive({
  selector: '[appEmailAndPhoneFinder]'
})
export class EmailAndPhoneFinderDirective implements OnChanges {

  @Input('appEmailAndPhoneFinder') text: string;

  constructor(private element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const emailResult = this.testEmail();
    const phoneNumberResult = this.testPhoneNumber();
    this.highlightPattern(emailResult, phoneNumberResult);
  }

  private testEmail(): string[] {
    const emailRegex = RegExp(regex.email.replace('^', '').replace('$', ''), 'g');
    return this.text.match(emailRegex);
  }

  private testPhoneNumber(): string[] {
    const phoneNumberPattern = regex.phoneNumber.replace('^', '').replace('*$', '{6,}');
    const phoneRegex = RegExp(phoneNumberPattern, 'g');
    return this.text.match(phoneRegex);
  }

  private highlightPattern(emailResult: string[], phoneNumberResult: string[]): void {
    setTimeout(() => {
      let innerHTML = this.element.nativeElement.innerHTML;
      if (emailResult) {
        emailResult.forEach(result => {
          const span = document.createElement('span');
          span.style.color = 'red';
          span.style.textDecoration = 'underline dotted red';
          span.title = 'These words are probably an email address';
          span.textContent = result;
          innerHTML = innerHTML.replace(result, span.outerHTML);
        });
      }

      if (phoneNumberResult) {
        phoneNumberResult.forEach(result => {
          const span = document.createElement('span');
          span.style.color = 'red';
          span.style.textDecoration = 'underline dotted red';
          span.title = 'These numbers are probably a phone number';
          span.textContent = result;
          innerHTML = innerHTML.replace(result, span.outerHTML);
        });
      }

      this.element.nativeElement.innerHTML = innerHTML;
    });
  }
}
