import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AuthService} from '../../core/services/auth-service.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  isSuccess: boolean;
  isError: boolean;
  forgetPasswordForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private logger: NGXLogger) {
    this.isSuccess = null;
  }

  ngOnInit(): void {
    this.forgetPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.maxLength(50)]],
    });
  }

  forgetPasswordFormSubmit(): void {
    this.subscriptions.push(this.authService.forgetPassword(this.forgetPasswordForm.value.email).subscribe(
      (res: any) => {
        this.isSuccess = true;
      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
