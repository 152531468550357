import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private subject = new BehaviorSubject(null);
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          // //console.log('will set the keep after router change to false');
          this.keepAfterRouteChange = false;
        } else {
          // clear alert message
          // //console.log('will clear the alerts');
          this.clear();
        }
      }
    })
  }

  getMessage(): Observable<any> {
  //  console.log('will return the alert');
    return this.subject.asObservable();
  }


  addMessage(message: string, type: string, keepAfterRouteChange: boolean = false) {
    // //console.log('will add a new alert:', message, type, keepAfterRouteChange);
    this.keepAfterRouteChange = keepAfterRouteChange;
    // //console.log('keepafterrouterchange:', this.keepAfterRouteChange);
    this.subject.next({type, text: message});
  }

  clear() {
    this.subject.next(null);
  }

}
