import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: string, nbOfLetters: number | { width: number, nbOfLetters: number }[]): string {
    let str = value;
    if (str && nbOfLetters) {
      if (typeof nbOfLetters === 'number') {
        str = str.slice(0, nbOfLetters);
        if (nbOfLetters < value.length) {
          str += '...';
        }
      } else {
        const windowWidth = window.innerWidth;
        nbOfLetters.sort((a, b) => a.width - b.width);
        let ellipsisConfig = nbOfLetters.find(config => windowWidth <= config.width);
        if (!ellipsisConfig) {
          ellipsisConfig = nbOfLetters[nbOfLetters.length - 1];
        }
        str = str.slice(0, ellipsisConfig.nbOfLetters);
        if (ellipsisConfig.nbOfLetters < value.length) {
          str += '...';
        }
      }
    }

    return str;
  }

}
