<div *ngIf="isAuthenticated; else notAuthenticatedTemplate"
     class="app header-fixed sidebar-fixed aside-menu-fixed sidebar-lg-show">

  <app-header></app-header>
  <div class="app-body">
    <app-navbar></app-navbar>
    <main class="main">
      <app-breadcrumb></app-breadcrumb>
      <div class="container-fluid">
        <div class="animated fadeIn">
          <app-alert></app-alert>
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </div>
  <footer class="app-footer mt-3">
    <div>
      <a href="https://isupplier.fr">iSupplier</a>
      <span>&copy; {{date|date:'yyyy'}} </span>
      <span> {{'app.footer.made-with'|translate}} </span> <fa-duotone-icon [icon]="fadHeart" [secondaryColor]="'tomato'"></fa-duotone-icon> <span> {{'app.footer.from-Paris'|translate}} </span>
    </div>
    <div class="ml-auto">
      <span> {{'app.footer.powered-by-iSupplier'|translate}} {{version}} </span>
      <a href="mailto:help@isupplier.fr">{{'app.footer.report-a-bug'|translate}} </a>
    </div>
  </footer>

</div>

<ng-template #notAuthenticatedTemplate>
  <router-outlet></router-outlet>
</ng-template>

<!--<app-user-ribbon></app-user-ribbon>-->
