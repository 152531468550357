<div class="modal-header mb-1">
  <h4 class="modal-title pull-left">{{'company.template.modal.default-variables.prompt'|translate}}</h4>
  <isp-button
    class="pull-right"
    [type]="ButtonTypeEnum.CLASSIC"
    [icon]="faPrint"
    (trigger)="printVariables()"
    [text]="'global.button.download'|translate"
    [colour]="ButtonColour.secondary"
  ></isp-button>
</div>
<div class="modal-content">
  <div class="modal-body">
    <div class="print-section" #printSection id="print-section">
      <table class="table-sm">
        <thead>
          <tr>
            <th>{{'company.template.modal.create-file.variables-defaults'|translate}}</th>
            <th>{{'global.common.description'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of variableKeys">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

