import {TemplateTypesModel} from './template-types.model';

export enum TemplateTypesEnum {
  FRAMEWORK_AGREEMENT = 'FRAMEWORK_AGREEMENT',
  APPLICATION_AGREEMENT = 'APPLICATION_AGREEMENT',
  AVENANT = 'AVENANT',
  ANNEXE = 'ANNEXE',
  RESILIATION = 'RESILIATION',
  UPLOADED = 'UPLOADED',
}

export enum TemplateMode {
  FILE = 'FILE',
  CONTRACT = 'CONTRACT'
}

export const TemplateTypes: TemplateTypesModel[] = [
  { value: TemplateTypesEnum.FRAMEWORK_AGREEMENT, template: [TemplateMode.FILE, TemplateMode.CONTRACT], disabled: false },
  { value: TemplateTypesEnum.APPLICATION_AGREEMENT, template: [TemplateMode.FILE, TemplateMode.CONTRACT], disabled: false },
  { value: TemplateTypesEnum.AVENANT, template: [TemplateMode.FILE], disabled: false },
  { value: TemplateTypesEnum.ANNEXE, template: [TemplateMode.FILE], disabled: false },
  { value: TemplateTypesEnum.RESILIATION, template: [TemplateMode.FILE], disabled: false },
];
