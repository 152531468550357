<div class="notification-toastr-container mr-3" (click)="clickOnNotification()">
  <div class="d-flex justify-content-start align-items-center px-2">
    <div class="icon-container mr-3">
      <ng-container [ngSwitch]="origin">
        <i class="fa fa-coffee" aria-hidden="true" *ngSwitchCase="'marketplace'"></i>
        <i class="fa fa-building-o" aria-hidden="true" *ngSwitchCase="'company'"></i>
        <i class="fa fa-suitcase" aria-hidden="true" *ngSwitchCase="'request'"></i>
        <i class="fa fa-handshake-o" aria-hidden="true" *ngSwitchCase="'contract'"></i>
        <i class="fa fa-bell" aria-hidden="true" *ngSwitchDefault></i>
      </ng-container>
    </div>
    <div class="flex-grow-1 py-2">
      <h5 class="notification-title">{{title}}</h5>
      <p class="notification-message mb-0">{{message}}</p>
    </div>
    <span class="notification-close" (click)="remove(); $event.stopPropagation()"><i class="fa fa-times" aria-hidden="true"></i></span>
  </div>
  <div>
    <progressbar max="100" [value]="width" type="info" class="progress"></progressbar>
  </div>
</div>




