import {Injectable} from '@angular/core';
import {AuthService} from "./auth-service.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  static readonly ENGLISH = 'GB';
  static readonly FRENCH = 'FR';
  static readonly LANGUAGE = 'lang';

  languages = [TranslatorService.ENGLISH, TranslatorService.FRENCH];

  constructor(private translate: TranslateService,
              private authService: AuthService) {
  }

  getLanguage() {
    if (this.authService.isAuthenticated()) {
      return this.authService.getUserLang();
    }
    if (localStorage.getItem(TranslatorService.LANGUAGE)) {
      return localStorage.getItem(TranslatorService.LANGUAGE);
    }
    return this.translate.getDefaultLang();
  }

  changeLang(event: any): void {
    localStorage.setItem(TranslatorService.LANGUAGE, event);
    this.translate.use(event);
  }
}
