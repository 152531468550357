export class PasswordStrengthResultModel {
  result: number;
  color: string;
  messages: string[];

  constructor() {
    this.result = 0;
    this.color = '#BBBBBB';
    this.messages = [];
  }
}
