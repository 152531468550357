<div class="row">
  <div class="col">
    <ul class="nav nav-tabs">
      <ng-container *ngFor="let item of menuList">
        <li class="nav-item">
          <div class="tab-link tab-label" routerLinkActive="active" [routerLink]="item.link">
            {{ item.title | translate }}
            <fa-duotone-icon class="ml-2" [icon]="item.icon" *ngIf="item.icon"></fa-duotone-icon>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
