import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BreadCrumb} from '../components/breadcrumb/model/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  constructor() {
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    let label = '';
    let path = '';

    if (route.routeConfig) {
      label = route.routeConfig.data ? route.routeConfig.data.breadcrumb : null;
      path = route.routeConfig.path;

      if (!label) {
        if (route.firstChild) {
          return this.buildBreadCrumb(route.firstChild, url, breadcrumbs);
        } else {
          return breadcrumbs;
        }
      }

      if (path.includes(':')) {
        const splitPath = path.split('/').map(value => {
          if (value.startsWith(':')) {
            const paramName = value.split(':')[1];
            value = route.snapshot.params[paramName];
          }
          return value;
        });
        path = splitPath.join('/');
      }

    } else {
      label = 'Home';
    }

    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label,
      url: nextUrl
    };

    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}
