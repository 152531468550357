export class PdfConfigModel {
  showOpenFileButton: boolean;
  showSecondaryToolbarButton: boolean;
  zoom: string | number;
  height: string;
  showDownloadButton: boolean;
  showPrintButton: boolean;
  showPresentationModeButton: boolean;
  textLayer: boolean;
  showBookmarkButton: boolean;
  useBrowserLocale: boolean;
  showUnverifiedSignatures: boolean;
  showRotateButton: boolean;
  fileName: string;


  constructor(
    zoom: string | number,
    height: string,
    showOpenFileButton?: boolean,
    showSecondaryToolbarButton?: boolean,
    showDownloadButton?: boolean,
    showPrintButton?: boolean,
    showPresentationModeButton?: boolean,
    textLayer?: boolean,
    showBookmarkButton?: boolean,
    useBrowserLocale?: boolean,
    showUnverifiedSignatures?: boolean,
    showRotateButton?: boolean
  ) {
    this.showOpenFileButton = showOpenFileButton;
    this.showSecondaryToolbarButton = showSecondaryToolbarButton;
    this.zoom = zoom;
    this.height = height;
    this.showDownloadButton = showDownloadButton;
    this.showPrintButton = showPrintButton;
    this.showPresentationModeButton = showPresentationModeButton;
    this.textLayer = textLayer;
    this.showBookmarkButton = showBookmarkButton;
    this.useBrowserLocale = useBrowserLocale;
    this.showUnverifiedSignatures = showUnverifiedSignatures;
    this.showRotateButton = showRotateButton;
  }
}
