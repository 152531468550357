import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Observable, Subscription, timer} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AlertService} from '../../core/services/alert.service';
import {AuthService} from '../../core/services/auth-service.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  isSuccess: boolean;
  isError: boolean;
  private token: string;
  resetPasswordForm: FormGroup;
  passwordValid: boolean;
  counter$: Observable<number>;
  count = 10;
  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private alertService: AlertService,
              private logger: NGXLogger,
              private fb: FormBuilder,
              private translateService: TranslateService) {
    this.isSuccess = false;
    this.isError = false;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.subscriptions.push(this.translateService.get('authentication.reset-password-ts.ng-on-init.error.already-connected').subscribe((data: any) => {
        this.alertService.addAlert(data, 'warning', true);
        this.router.navigate(['/']);
      }));
    }
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.token = params.token;
        if (!this.token) {
          this.subscriptions.push(this.translateService.get('authentication.reset-password-ts.ng-on-init.error.missing-token').subscribe((data: any) => {
            this.alertService.addAlert(data, 'warning', true);
            this.router.navigate(['/login']);
          }));
        }
      })
    );

    this.resetPasswordForm = this.fb.group({
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8)]]
    });
  }

  resetPasswordFormSubmit(): void {
    this.subscriptions.push(
      this.authService.resetPassword(this.token, this.resetPasswordForm.value.password).subscribe(
        () => {
          this.isSuccess = true;
          this.counter$ = timer(0, 1000).pipe(
            take(this.count),
            map(() => {
              // console.log('the value of your count:', this.count);
              if (this.count === 1) {
                this.router.navigate(['/login']);
              }
              return --this.count;
            })
          );
        },
        (error: any) => {
          this.logger.error(error.url, '- STATUS :', error.status);
          this.isError = true;
        }
      )
    );
  }

  setPasswordValid($event: boolean): void {
    this.passwordValid = $event;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

}
