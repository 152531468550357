import {Component, OnDestroy, OnInit} from '@angular/core';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {
  fadeInAnimation,
  fadeOutHeightAnimation,
  slideIn50StaggerAnimation,
  slideInAnimation
} from '../../shared/animation/common.animation';
import {ListContentComponent} from '../../shared/components/list-content/list-content.component';
import {Subscription} from 'rxjs';
import {regex} from '../../shared/regex/form.regex';
import {FormBuilder} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ListContentModel} from '../../shared/models/list-content.model';
import {ActivityReportService} from '../services/activity-report.service';
import * as _ from 'lodash';
import {ActivitySheetModel, ActivitySheetStatusEnum} from '../models/activity-sheet.model';
import * as moment from 'moment';
import {ModalConfirmComponent} from '../../shared/components/modal-confirm/modal-confirm.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-activity-sheets',
  templateUrl: './activity-sheets.component.html',
  styleUrls: ['./activity-sheets.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        useAnimation(fadeOutHeightAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class ActivitySheetsComponent extends ListContentComponent implements OnInit, OnDestroy {

  constructor(
    private activityReportService: ActivityReportService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      purchaseOrderNumber: [null],
      buyerCompanyName: [null],
      supplierCompanyName: [null],
      providerName: [null],
      projectName: [null],
      status: [null],
      period: [null]
    });
  }
  subscriptions: Subscription[] = [];
  listContent: ActivitySheetModel[] = [];
  status = Object.values(ActivitySheetStatusEnum);
  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp(
      '^(accountingMonth|buyer.companyName|' +
      'buyer.projectName|supplier.companyName|supplier.employeeName|supplierInvoice.type|' +
      'validationDate|submissionDate|' +
      'supplierInvoice.period|status)\,(asc|desc)$'
    ),
    search: RegExp('.{3,}'),
    buyerCompanyName: RegExp(regex.companyName),
    supplierCompanyName: RegExp(regex.companyName),
    projectName: RegExp(regex.companyName),
    providerName: RegExp(regex.companyName),
    purchaseOrderNumber: RegExp('^[a-zA-Z0-9_]+$'),
    status: RegExp(`^(${this.status.join('|')})(\,(${this.status.join('|')}))*$`),
    period: 'rangeDate'
  };

  protected readonly ActivitySheetStatusEnum = ActivitySheetStatusEnum;

  ngOnInit() {
    super.ngOnInit();
    this.sort = 'date';
    this.sortDirection = 'asc';
    this.subscribeToQueryParam();
  }

  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        if (paramKey === 'period') {
          this.advancedSearchForm.get('period').setValue(params[paramKey].split(',').map(date => new Date(date)));
        } else if (paramKey === 'status') {
          const type = params[paramKey].split(',');
          this.advancedSearchForm.get('status').setValue(_.uniq(type));
        } else {
          this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        }
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    this.logger.info('your params in mapAdvancedSearchFormToQueryParam:', advancedSearchForm);
    if (advancedSearchForm.hasOwnProperty('period') && advancedSearchForm.period) {
      advancedSearchForm.period = advancedSearchForm.period.map(date => moment(date).format('YYYY-MM-DD')).join();
    }
    if (advancedSearchForm.hasOwnProperty('status') && advancedSearchForm.status) {
      advancedSearchForm.status = advancedSearchForm.status.join();
    }
    return advancedSearchForm;
  }

  // Override method
  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'accountingMonth,asc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.activityReportService.mapAdvancedSearchForm(params);
    this.logger.info('your advanced search model is: ', advancedSearch);
    this.subscriptions.push(this.activityReportService.getActivitySheetsByPage(page, size, sort, search, advancedSearch).subscribe(
      (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
        this.logger.debug('invoice list retrieved : ', res);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  confirmTimesheetModeration(status: ActivitySheetStatusEnum, activitySheet: ActivitySheetModel) {
    const textData: { prompt: string , placeholder: string } = {prompt: '', placeholder: ''};
    const params: {status: ActivitySheetStatusEnum, refusalComment: string, approvalComment: string } = {status: null as unknown as ActivitySheetStatusEnum, refusalComment: null, approvalComment: null};
    if (status === ActivitySheetStatusEnum.CANCELLED) {
      params.status = activitySheet.status === ActivitySheetStatusEnum.SUBMITTED ? ActivitySheetStatusEnum.DRAFT : ActivitySheetStatusEnum.CANCELLED;
      textData.prompt = this.translateService.instant('activity-report.activity-sheet.modal.cancel.prompt');
      textData.placeholder = this.translateService.instant('shared.components.modal-confirm.form.comment-placeholder');
    } else if (status === ActivitySheetStatusEnum.VALIDATED) {
      params.status = status;
      textData.prompt = this.translateService.instant('activity-report.activity-sheet.modal.validate.prompt');
      textData.placeholder = this.translateService.instant('activity-report.activity-sheet.modal.validate.placeholder');
    }

    const initialState = {
      message: textData.prompt,
      placeholder: textData.placeholder,
      commentRequired: true,
      commentMinLength: 10,
      commentMaxLength: 255
    };
    const modalRef = this.modalService.show(ModalConfirmComponent, {
      class: 'modal-lg',
      initialState
    });
    this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
      comment => {
        params[status === ActivitySheetStatusEnum.CANCELLED ? 'refusalComment' : 'approvalComment'] = comment;
        this.moderateActivitySheet(status, params, activitySheet);
      }
    ));
  }

  moderateActivitySheet(status: ActivitySheetStatusEnum, params, activitySheet: ActivitySheetModel) {
    this.activityReportService.moderateActivitySheet(activitySheet, params).subscribe({
      next: (data: any) => {
        activitySheet.status = data.status;
        this.toastrService.success(this.translateService.instant(`activity-report.activity-sheet.modal.${status === ActivitySheetStatusEnum.VALIDATED ? 'validate' : 'cancel'}.success`));
      },
      error: () => this.toastrService.error(this.translateService.instant('global.ts.error'))
    });
  }

  sendReminder(activitySheet: ActivitySheetModel) {
    const initialState = {
      message: this.translateService.instant('activity-report.activity-sheet.modal.reminder.prompt'),
    };
    const modalRef = this.modalService.show(ModalConfirmComponent, {
      class: 'modal-lg',
      initialState
    });
    this.subscriptions.push(modalRef.content.actionConfirmed.subscribe( () => {
      this.activityReportService.sendReminder(activitySheet.id).subscribe({
        next: () => {
          this.toastrService.info(this.translateService.instant('activity-report.activity-sheet.modal.reminder.success'));
          activitySheet.reminderAvailable = false;
        },
        error: () => this.toastrService.error(this.translateService.instant('global.ts.error'))
      });
    }));
  }

  getActivitySheetClass(status: ActivitySheetStatusEnum) {
    let titleClass = '';
    switch (status) {
      case ActivitySheetStatusEnum.DRAFT: {
        titleClass = 'badge-warning';
        break;
      }
      case ActivitySheetStatusEnum.REFUSED: {
        titleClass = 'badge-danger';
        break;
      }
      case ActivitySheetStatusEnum.SUBMITTED: {
        titleClass = 'badge-info';
        break;
      }
      case ActivitySheetStatusEnum.VALIDATED: {
        titleClass = 'badge-success';
        break;
      }
      case ActivitySheetStatusEnum.CANCELLED: {
        titleClass = 'badge-darker-red';
        break;
      }
    }
    return titleClass;
  }

  displayCancel(activitySheet: ActivitySheetModel) {
    const { status, invoiceGenerated } = activitySheet;
    return !invoiceGenerated
      && ![ActivitySheetStatusEnum.DRAFT, ActivitySheetStatusEnum.REFUSED, ActivitySheetStatusEnum.CANCELLED].includes(status);
  }

  clearAdvanceSearchDate(formControl: string) {
    this.advancedSearchForm.get(formControl).reset();
    this.advancedSearchForm.get(formControl).markAsDirty();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
