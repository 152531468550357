import {Injectable} from '@angular/core';
import {RxStompService} from '@stomp/ng2-stompjs';
import { WebSocketOptions } from '../models/websocket.option';
import {FixedStompConfig, WebSocketService} from './websocket.service';

export const notificationStompConfig: FixedStompConfig = {
  webSocketFactory: () => {
    console.log('web socket');
    return new WebSocket( window.sessionStorage.getItem('ws_config') + '/stomp');
  }
};

@Injectable()
export class NotificationWebsocketService extends WebSocketService {
  constructor(stompService: RxStompService) {
    super(
      stompService,
      notificationStompConfig,
      new WebSocketOptions('/topic/notification', '/user/queue/notification')
    );
  }
}
