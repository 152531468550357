<div class="modal-body p-5">
  <div class="icon-warning">
    <div class="icon-warning-content">!</div>
  </div>
  <ng-container *ngIf="commentRequired">
    <form [formGroup]="commentForm" (ngSubmit)="confirmAction()">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h4 class="font-weight-bold mb-2">{{message}}</h4>
        <p class="text-dark-gray mt-2" *ngIf="additionalMessage">{{additionalMessage}}</p>

        <ng-container *ngIf="rejectReasons">
          <div class="form-group w-100 row align-items-center">
            <label for="rejectReason" class="col-md-4 col-form-label">{{ 'global.common.reject-reason' | translate }}</label>
            <div class="col-md-8">
              <ng-select
                id="rejectReason"
                [items]="rejectReasonsList"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                [clearable]="false"
                formControlName="rejectReasons"
                class="ng-select-custom"
                bindLabel="label"
                bindValue="value"
              >
                <ng-template ng-notfound-tmp>
                  <div class="ng-option ng-option-disabled ng-star-inserted">
                    {{ 'global.dropdown.not-found' | translate }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="form-group w-100 row align-items-center">
            <label for="language" class="col-md-4 col-form-label">{{ 'global.common.language' | translate }}</label>
            <div class="col-md-8">
              <ng-select
                id="language"
                [items]="languages"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                [clearable]="false"
                formControlName="language"
                class="ng-select-custom"
              >
                <ng-template ng-option-tmp let-item="item">
                  {{ 'global.lang.' + item.toLowerCase() | translate }}
                </ng-template>
                <ng-template ng-label-tmp let-items="item">
                  <div *ngIf="items">
                    {{ 'global.lang.' + items.toLowerCase() | translate }}
                  </div>
                </ng-template>
                <ng-template ng-notfound-tmp>
                  <div class="ng-option ng-option-disabled ng-star-inserted">
                    {{ 'global.dropdown.not-found' | translate }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>

        </ng-container>

        <div class="form-group w-100 mt-3">
          <textarea
            class="form-control" cols="30" rows="3" formControlName="comment"
            maxlength="255"
            [placeholder]="placeholder ? placeholder:'shared.components.modal-confirm.form.comment-placeholder'|translate"
          ></textarea>
          <span class="float-right mr-1 text-dark-gray" *ngIf="this.commentForm.get('comment').value">
            {{this.commentForm.get('comment').value.length}}/{{commentMaxLength}}
          </span>
          <span class="float-right mr-1 text-dark-gray" *ngIf="!this.commentForm.get('comment').value">
            0/{{commentMaxLength}}
          </span>
          <app-form-feedback
            *ngIf="commentForm.get('comment').errors?.required && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
            message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
          <app-form-feedback
            *ngIf="commentForm.get('comment').errors?.maxlength && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
            message="{{'global.feedback.length'|translate: {max: commentMaxLength} }}">
          </app-form-feedback>
          <app-form-feedback
            *ngIf="commentForm.get('comment').errors?.minlength && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
            message="{{'global.feedback.length-min'|translate: {min: commentMinLength} }}">
          </app-form-feedback>
        </div>

        <div class="mt-2">
          <isp-button
            class="px-2"
            [type]="ButtonTypeEnum.CLASSIC"
            [text]="'global.button.cancel'|translate"
            [colour]="ButtonColour.tertiary"
            [disabled]="loading"
            (trigger)="cancelAction()"
          ></isp-button>
          <isp-button
            class="px-2"
            [action]="ButtonActionEnum.SUBMIT"
            [type]="ButtonTypeEnum.CLASSIC"
            [text]="'global.button.yes-i-confirm'|translate"
            [colour]="ButtonColour.success"
            [loader]="loading"
            [disabled]="commentForm.invalid"
          ></isp-button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="!commentRequired">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h4 class="font-weight-bold mb-2">{{message}}</h4>
      <p class="text-dark-gray mb-0" *ngIf="additionalMessage">{{additionalMessage}}</p>
      <div class="mt-4">
        <isp-button
          class="px-2"
          [type]="ButtonTypeEnum.CLASSIC"
          [text]="'global.button.cancel'|translate"
          [colour]="ButtonColour.tertiary"
          [disabled]="loading"
          (trigger)="cancelAction()"
        ></isp-button>
        <isp-button
          class="px-2"
          [type]="ButtonTypeEnum.CLASSIC"
          [text]="'global.button.yes-i-confirm'|translate"
          [colour]="ButtonColour.success"
          [disabled]="loading"
          (trigger)="confirmAction()"
        ></isp-button>
      </div>
    </div>
  </ng-container>
</div>
