import { Injectable } from '@angular/core';
import {BillingAddressModel} from '../models/billing-address.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../assets/environments/environment';
import {commonProperties} from '../../../../../../assets/environments/environment.common';
import {AuthService} from '../../../../../core/services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class BillingAddressService {

  constructor(private _httpClient: HttpClient) { }


  retrieveBillingAddresses(companyId: string, active: boolean = false): Observable<BillingAddressModel[]> {
    const params = { active: active };
    return this._httpClient.get<BillingAddressModel[]>(environment.api_root + commonProperties.billingAddress.replace(':companyId', companyId), {params});
  }
}
