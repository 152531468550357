import {DatePipe, registerLocaleData} from '@angular/common';
import {Inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
@Pipe({name: 'localizedDate', pure: false})
export class LocalizedDatePipe implements PipeTransform {
  constructor(@Inject(TranslateService) private translateService: TranslateService) {}

  transform(value: string | number | Date, pattern: string = 'mediumDate'): string {
    let lang = this.translateService.currentLang;
    if (lang === 'GB') {
      lang = 'en';
    }
    if (!['fr', 'en'].includes(lang)) {
      lang = 'fr';
    }
    const datePipe: DatePipe = new DatePipe(lang);
    return datePipe.transform(value, pattern);
  }
}
