import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})

export class PdfPreviewComponent {

  @Input() srcPdf;
  @Input() pdfZoom = "1";
  numPage: number = 1;
  totalPages: number;


  constructor() {
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  increment() {
    this.numPage++;
  }

  decrement() {
    this.numPage--;
  }

}
