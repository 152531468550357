<form [formGroup]="employeeImageForm" (ngSubmit)="employeeImageFormSubmit()">
  <div class="avatar-upload">
    <div class="avatar-edit text-center">
      <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
             (change)="uploadPhoto($event)"/>
      <label for="imageUpload"><i class="fa fa-pencil" aria-hidden="true"></i></label>
    </div>
    <ng-container *ngIf="name; else emptyNameBlock">
      <ngx-avatars [name]="name" bgColor="#F7F7F9" fgColor="#8B5D5D" size="160" textSizeRatio="2" initialsSize="2">
      </ngx-avatars>
    </ng-container>
    <ng-template #emptyNameBlock>
      <div class="icon-empty">
        <i class="fa fa-image" aria-hidden="true"></i>
      </div>
    </ng-template>

    <button *ngIf="file?.name" class="btn btn-success btn-sm" type="submit"
            [disabled]="employeeImageForm.invalid || disableButton ">
      {{savePictureText}}
    </button>
  </div>
</form>




