import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ListContentModel} from '../../shared/models/list-content.model';
import {NGXLogger} from 'ngx-logger';
import {ActivitySheetAdvancedSearchModel} from '../models/activity-sheet-advanced-search.model';
import {ActivitySheetModel} from '../models/activity-sheet.model';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ActivityReportService {

  constructor(private httpClient: HttpClient,
              private logger: NGXLogger) {
  }

  getActivitySheetsByPage(page: number, size: any, sort: any, search: any, advancedSearch) {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.projectName) {
        params = params.set('projectName', advancedSearch.projectName);
      }
      if (advancedSearch.purchaseOrderNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.purchaseOrderNumber);
      }
      if (advancedSearch.buyerCompanyName) {
        params = params.set('buyerCompanyName', advancedSearch.buyerCompanyName);
      }
      if (advancedSearch.supplierCompanyName) {
        params = params.set('supplierCompanyName', advancedSearch.supplierCompanyName);
      }
      if (advancedSearch.providerName) {
        params = params.set('providerName', advancedSearch.providerName);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
      if (advancedSearch.period) {
        const periodRange = advancedSearch.period.split(',');
        params = params.set('startDate', periodRange[0]);
        params = params.set('endDate', periodRange[1]);
      }
    }
    params = params.set('page', page.toString()).set('size', size.toString()).set('sort', sort).set('summary', 'true');
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.activitySheets, {params});
  }

  mapAdvancedSearchForm(params: any) {
    this.logger.info('your params entries:', params);
    const advancedSearch: ActivitySheetAdvancedSearchModel = new ActivitySheetAdvancedSearchModel();
    if (params.purchaseOrderNumber) {
      advancedSearch.purchaseOrderNumber = params.purchaseOrderNumber;
    }
    if (params.buyerCompanyName) {
      advancedSearch.buyerCompanyName = params.buyerCompanyName;
    }
    if (params.period) {
      advancedSearch.period = params.period;
    }
    if (params.supplierCompanyName) {
      advancedSearch.supplierCompanyName = params.supplierCompanyName;
    }
    if (params.providerName) {
      advancedSearch.providerName = params.providerName;
    }
    if (params.projectName) {
      advancedSearch.projectName = params.projectName;
    }
    if (params.status) {
      advancedSearch.status = params.status;
    }
    return advancedSearch;
  }

  moderateActivitySheet(activitySheet: ActivitySheetModel, params: any) {
    return this.httpClient.patch(environment.api_root + commonProperties.activitySheetById.replace(':activitySheetId', activitySheet.id.toString()), params);
  }

  sendReminder(activitySheetId: number): Observable<void> {
    return this.httpClient.post<void>(environment.api_root + commonProperties.activitySheetReminder.replace(':activitySheetId', activitySheetId.toString()), {});
  }
}
