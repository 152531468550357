import {Component, Input} from '@angular/core';
import {PdfConfigModel} from './model/pdf-config.model';

@Component({
  selector: 'extended-pdf-preview',
  templateUrl: './extended-pdf-preview.component.html',
  styleUrls: ['./extended-pdf-preview.component.scss']
})
export class ExtendedPdfPreviewComponent {
  @Input() source: any;
  @Input() config: PdfConfigModel;
}
