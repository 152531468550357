import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserRibbonComponent} from './components/user-ribbon/user-ribbon.component';
import {AlertComponent} from './components/alert/alert.component';

import {PageTitleComponent} from './components/page-title/page-title.component';
import {PageMenuComponent} from './components/page-menu/page-menu.component';
import {ToastrModule} from 'ngx-toastr';
import {RouterModule} from '@angular/router';
import {StatusPipe} from './pipes/status.pipe';
import {NoContentComponent} from './components/no-content/no-content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormFeedbackComponent} from './components/form-feedback/form-feedback.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {AvatarModule} from 'ngx-avatars';
import {DefaultPipe} from './pipes/default.pipe';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {CurrencyFormatPipe} from './pipes/currency-format.pipe';
import {BooleanFormatPipe} from './pipes/boolean-format.pipe';
import {ProfilePictureComponent} from './components/profile-picture/profile-picture.component';
import {PdfPreviewComponent} from './components/pdf-preview/pdf-preview.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NoDataComponent} from './components/no-data/no-data.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {EmailAndPhoneFinderDirective} from './directives/email-and-phone-finder.directive';
import {ModalConfirmComponent} from './components/modal-confirm/modal-confirm.component';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {ListContentComponent} from './components/list-content/list-content.component';
import {KpiCardComponent} from './components/kpi-card/kpi-card.component';
import {KpiProgressBarComponent} from './components/kpi-progress-bar/kpi-progress-bar.component';
import {NotificationToastrComponent} from './components/notification-toastr/notification-toastr.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {KpiAvatarComponent} from './components/kpi-avatar/kpi-avatar.component';
import {MessageComponent} from './components/message/message.component';
import {PageSectionTitleComponent} from './components/page-section-title/page-section-title.component';
import {VerticalPageMenuComponent} from './components/vertical-page-menu/vertical-page-menu.component';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ImageCropperComponent, ImageCropperModule} from 'ngx-img-cropper';
import {ExtendedPdfPreviewComponent} from './components/extended-pdf-preview/extended-pdf-preview.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {NavDropdownComponent} from './components/nav-dropdown/nav-dropdown.component';
import {LocalizedDatePipe} from './pipes/localized-date/localized-date.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize/sanitize-html.pipe';
import {NgApexchartsModule} from 'ng-apexcharts';
import {ModalIdleWarningComponent} from './components/modal-idle-warning/modal-idle-warning.component';
import {BillingAddressDropdownComponent} from './components/dropdowns/billing-address-dropdown/billing-address-dropdown.component';
import {TemplateCardComponent} from './components/template-card/template-card.component';
import {
  TemplateTypesDropdownComponent
} from './components/dropdowns/templates/template-types-dropdown/template-types-dropdown.component';
import {
  TemplateSignatoryDropdownComponent
} from './components/dropdowns/templates/template-signatory-dropdown/template-signatory-dropdown.component';
import {
  TemplateFileTemplatesDropdownComponent
} from './components/dropdowns/templates/template-file-templates-dropdown/template-file-templates-dropdown.component';
import {DefaultVariablesModalComponent} from './components/default-variables-modal/default-variables-modal.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {environment} from '../../assets/environments/environment';
import { IsupplierSharedModule } from '@i-supplier/angular-shared-module';

@NgModule({
  declarations: [
    AlertComponent,
    MessageComponent,
    PageTitleComponent,
    UserRibbonComponent,
    NoContentComponent,
    PageMenuComponent,
    FormFeedbackComponent,
    CurrencyFormatPipe,
    BillingAddressDropdownComponent,
    DefaultPipe,
    BooleanFormatPipe,
    ProfilePictureComponent,
    StatusPipe,
    PdfPreviewComponent,
    NoDataComponent,
    SpinnerComponent,
    EllipsisPipe,
    EmailAndPhoneFinderDirective,
    ModalConfirmComponent,
    ListContentComponent,
    KpiCardComponent,
    KpiProgressBarComponent,
    NotificationToastrComponent,
    KpiAvatarComponent,
    PageSectionTitleComponent,
    LocalizedDatePipe,
    VerticalPageMenuComponent,
    ExtendedPdfPreviewComponent,
    NavDropdownComponent,
    ModalIdleWarningComponent,
    SanitizeHtmlPipe,
    TemplateCardComponent,
    TemplateTypesDropdownComponent,
    TemplateSignatoryDropdownComponent,
    TemplateFileTemplatesDropdownComponent,
    DefaultVariablesModalComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        AlertModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            preventDuplicates: false,
            closeButton: true,
            progressBar: true,
            tapToDismiss: false,
            extendedTimeOut: 5000
        }),
        PaginationModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgSelectModule,
        AvatarModule,
        NgApexchartsModule,
        NgxIntlTelInputModule,
        ImageCropperModule,
        InfiniteScrollModule,
        PdfViewerModule,
        NgxSpinnerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
        }),
        NgxExtendedPdfViewerModule,
        FontAwesomeModule,
        IsupplierSharedModule.forRoot({
          api_root: environment.api_root
        })
    ],
  exports: [
    FormsModule,
    IsupplierSharedModule,
    ReactiveFormsModule,
    LocalizedDatePipe,
    UserRibbonComponent,
    AlertComponent,
    PageTitleComponent,
    NoContentComponent,
    FormFeedbackComponent,
    ProfilePictureComponent,
    PageMenuComponent,
    SpinnerComponent,
    StatusPipe,
    EllipsisPipe,
    EmailAndPhoneFinderDirective,
    CurrencyFormatPipe,
    DefaultPipe,
    BooleanFormatPipe,
    ToastrModule,
    PaginationModule,
    TooltipModule,
    PopoverModule,
    AlertModule,
    ProgressbarModule,
    NgxIntlTelInputModule,
    AvatarModule,
    ImageCropperModule,
    ImageCropperComponent,
    PdfPreviewComponent,
    NoDataComponent,
    ModalModule,
    NgSelectModule,
    BsDatepickerModule,
    BillingAddressDropdownComponent,
    InfiniteScrollModule,
    ListContentComponent,
    KpiCardComponent,
    KpiProgressBarComponent,
    KpiAvatarComponent,
    MessageComponent,
    PageSectionTitleComponent,
    VerticalPageMenuComponent,
    TranslateModule,
    ExtendedPdfPreviewComponent,
    NgApexchartsModule,
    NavDropdownComponent,
    ModalIdleWarningComponent,
    SanitizeHtmlPipe,
    TemplateCardComponent,
    TemplateTypesDropdownComponent,
    TemplateSignatoryDropdownComponent,
    TemplateFileTemplatesDropdownComponent,
    DefaultVariablesModalComponent
  ],
  providers: [StatusPipe],
  entryComponents: [ModalConfirmComponent, NotificationToastrComponent]
})
export class SharedModule {}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
