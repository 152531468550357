<div *ngIf="message" class="card notification-div box-shadow-hover slide-in-animation mb-3 type-card">
  <div class="row">
    <div class="col-sm-6 text-align">
      <span [innerHTML]="message.text"></span>
    </div>
    <div class="col-sm-6 d-flex justify-content-end align-items-end mb-3">
      <a [routerLink]="['/marketplace/calls-for-tender']" class="btn btn-lg btn-block btn-tender mb-3">{{'shared.components.message.button.call-for-tender'|translate}}</a>
      <a [routerLink]="['/requests']" class="btn btn-lg btn-block btn-primary mb-3">{{'shared.components.message.button.create-request'|translate}}</a>
    </div>

  </div>
</div>
