export class TemplateModel {
  id?: string;
  templateName?: string;
  createdDate?: string;
  creatorName?: string;
  lastUpdatedDate?: string;
  description?: string;
  archived?: boolean;
  type?: string;
  archiveable?: boolean;
  containsUserSignature?: boolean;
  containsBillableCompanySignature?: boolean;
}

export class Pageable {
  sort: any[];
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export class TemplateResponse {
  content: TemplateModel[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: any[];
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface TemplatePostResource {
  fileName: string;
  fileContent: string;
  description: string;
  type: string;
  fields: { [key: string]: string };
}

export interface TemplateVariablesModel {
  statics: string[];
  defaults: string[];
  all: string[];
}

export interface TemplateGroupDetailsModel extends TemplateModel {
  templates: TemplateInfo[];
}

export interface TemplateInfo {
  template: TemplateModel;
  signerType: string;
  filename: string;
}
