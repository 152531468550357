<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">

        <div class="card-group">


          <div class="card">

            <div class="card-body">

              <div class="text-center brand-jumbotron">
                <img src="assets/brand/logo.svg" class="img-fluid isupplier-logo"
                     alt="iSupplier logo"/>
                <h4 class="login-heading mb-4">{{'authentication.account-validation-heading'|translate}}</h4>
              </div>


              <div class="row">


                <div class="col-md-12">

                  <div *ngIf="!isSuccess && !isError">

                    <p class="text-center">
                      {{'authentication.account-validation.description'|translate}}
                    </p>

                    <form [formGroup]="accountValidationForm"
                          (ngSubmit)="accountValidationFormSubmit()">

                      <div class="form-group">
                        <label for="password" hidden></label>
                        <input class="form-control" id="password" type="password"
                               placeholder="{{'authentication.account-validation.form.password-placeholder'|translate}}" formControlName="password"/>
                      </div>

                      <div class="form-group">
                        <label for="confirmPassword" hidden></label>
                        <input class="form-control" id="confirmPassword" type="password"
                               placeholder="{{'authentication.account-validation.form.confirm-password-placeholder'|translate}}"
                               formControlName="confirmPassword"/>
                      </div>

                      <div class="form-group">
                        <app-password-meter
                          [password]="accountValidationForm.value.password" [numberRequired]="2"
                          (passwordValid)="setPasswordValid($event)"></app-password-meter>
                      </div>

                      <button class="btn btn-primary btn-block font-weight-bold mb-2" type="submit"
                              [disabled]="!(passwordValid && accountValidationForm.value.password === accountValidationForm.value.confirmPassword)">
                        <em class="fa fa-unlock"></em> {{'authentication.account-validation.form.button.activate-my-account'|translate}}
                      </button>
                    </form>

                  </div>


                  <div class="text-center" *ngIf="isError && !isSuccess">

                    <div class="swal2-icon swal2-error swal2-animate-error-icon"
                         style="display: flex;">
                  <span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span
                    class="swal2-x-mark-line-right"></span></span></div>

                    <p class="error-message">
                      {{'authentication.account-validation.form.error-message'|translate}}
                    </p>

                    <a class="btn btn-link btn-block  mb-2" [routerLink]="['/login']">
                      {{'authentication.return-login'|translate}}
                    </a>

                  </div>

                  <div class="text-center" *ngIf="isSuccess && !isError">

                    <div class="swal2-icon swal2-success swal2-animate-success-icon">
                      <div class="swal2-success-circular-line-left"
                           style="background-color: rgb(255, 255, 255);"></div>
                      <span class="swal2-success-line-tip"></span>
                      <span class="swal2-success-line-long"></span>
                      <div class="swal2-success-ring"></div>
                      <div class="swal2-success-fix"
                           style="background-color: rgb(255, 255, 255);"></div>
                      <div class="swal2-success-circular-line-right"
                           style="background-color: rgb(255, 255, 255);"></div>
                    </div>

                    <p class="success-message">
                      <span><strong>{{'authentication.account-validation.form.success-message.strong'|translate}}</strong></span>
                      <span> {{'authentication.account-validation.form.success-message.text'|translate}}</span>
                      <span><strong>{{counter$ | async}}</strong></span>
                      <span>{{'authentication.account-validation.form.success-message.seconds'|translate}}</span>
                    </p>

                    <a class="btn btn-primary btn-block  mb-2" [routerLink]="['/login']">
                      {{'authentication.return-login'|translate}}
                    </a>

                  </div>


                </div>


              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
