export class PasswordStrengthModel {
  length: boolean;
  numberRequired: boolean;
  upperCase: boolean;
  specialCharacter: boolean;

  constructor() {
    this.length = false;
    this.numberRequired = false;
    this.upperCase = false;
    this.specialCharacter = false;
  }
}
