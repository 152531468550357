import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ButtonActionEnum, ButtonTypeEnum, IspColoursEnum} from '@i-supplier/angular-shared-module';
import {TranslateService} from '@ngx-translate/core';
import {RejectReasonsEnum} from '../../enums/RejectReasonsEnum';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, OnDestroy {

  constructor(
    public modalRef: BsModalRef,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.commentForm = this.fb.group({
      rejectReasons: [null],
      language: [{value: null, disabled: true}],
      comment: [null, [Validators.required, Validators.maxLength(this.commentMaxLength), Validators.minLength(this.commentMinLength)]]
    });
  }
  // Input variables
  placeholder: string;
  message: string;
  additionalMessage: string;
  rejectReasons: boolean = false;
  commentRequired: boolean = false;
  commentMaxLength: number = 255;
  commentMinLength: number = 0;
  rejectReasonsData: {lang: string, list: {label: string, value: string}[], documentType: string};

  // Output variables
  @Output() actionConfirmed = new EventEmitter<string>();
  @Output() actionCanceled = new EventEmitter();

  // Intern variables
  commentForm: FormGroup;
  loading: boolean = false;
  languages: Array<string>;
  rejectReasonsList: any;
  subscriptions: Subscription[] = [];
  protected readonly ButtonActionEnum = ButtonActionEnum;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly ButtonColour = IspColoursEnum;

  ngOnInit(): void {
    this.setRejectReasonsLogic();
    this.setCommentForValidation();
  }

  private setCommentForValidation() {
    this.commentForm.get('comment').setValidators([Validators.required, Validators.maxLength(this.commentMaxLength), Validators.minLength(this.commentMinLength)]);
    this.commentForm.get('comment').updateValueAndValidity();
  }

  confirmAction(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (this.commentRequired) {
      this.actionConfirmed.emit(this.commentForm.get('comment').value.trim());
    } else {
      this.actionConfirmed.emit();
    }
    this.modalRef.hide();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  cancelAction(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.actionCanceled.emit();
    this.modalRef.hide();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  private setRejectReasonsLogic() {
    if (this.rejectReasons) {
      this.languages = this.translateService.getLangs();
      this.rejectReasonsList = this.rejectReasonsData.list;

      if (this.rejectReasonsData.lang === 'FR') {
        this.commentForm.get('language').setValue(this.rejectReasonsData.lang);
      }

      this.subscriptions.push(this.commentForm.get('rejectReasons').valueChanges.subscribe(() => {
        this.commentForm.get('language').enable();
        if (this.commentForm.get('language').value) {
          this.setSpecificLangReason(this.commentForm.get('language').value, this.rejectReasonsData.documentType);
        }
      }));
      this.subscriptions.push(this.commentForm.get('language').valueChanges.subscribe(lang => {
        if (lang) {this.setSpecificLangReason(lang, this.rejectReasonsData.documentType); }
      }));
    }
  }

  setSpecificLangReason(lang: string, documentType: string) {
    this.subscriptions.push(this.translateService.getTranslation(lang).subscribe((translations) => {
      const rejectionReason = translations['legal-documents']['rejection-list'][this.commentForm.get('rejectReasons').value]['label'];
      const document = translations['core']['components']['breadcrumb'][documentType];
      this.commentForm.get('comment').setValue(rejectionReason.replace('{{document}}', document));
    }));
  }

  ngOnDestroy() {
    this.subscriptions.map(sub => sub.unsubscribe());
  }
}
