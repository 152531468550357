import {HttpClient} from '@angular/common/http';
import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from './core/services/auth-service.service';
import {TranslatorService} from './core/services/translator.service';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalIdleWarningComponent} from './shared/components/modal-idle-warning/modal-idle-warning.component';
import {commonProperties} from '../assets/environments/environment.common';
import {NavigationStart, Router} from '@angular/router';
import {faHeart} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean = false;
  title = 'SA-iSupplier';
  version: string;
  date: Date;

  userId: any;
  modalRef: BsModalRef;

  private isActive = false;
  fadHeart = faHeart;

  constructor(public http: HttpClient,
              private authService: AuthService,
              private router: Router,
              private translatorService: TranslatorService,
              private translateService: TranslateService,
              private modalService: BsModalService) {
    this.translateService.addLangs(['FR', 'GB']);
    this.translateService.setDefaultLang('GB');
    this.translatorService.changeLang(this.translatorService.getLanguage());
  }

  ngOnInit(): void {
    this.date = new Date();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isAuthenticated = this.authService.isAuthenticated();
      }
    });
    setInterval(() => {
      if (!localStorage.getItem(commonProperties.token_expiration)) {
        return;
      }
      const date = new Date(localStorage.getItem(commonProperties.token_expiration));
      const actual = new Date();
      const remainingMinutes = (date.getTime() - actual.getTime()) / 60 / 1000;
      if (this.isActive) {
        if (remainingMinutes <= 20) {
          this.refreshToken();
        }
      } else if (remainingMinutes <= 0) {
        this.router.navigate(['login']);
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = null;
        }
      } else if (remainingMinutes <= 2) {
        if (!this.modalRef) {
          this.modalRef = this.modalService.show(ModalIdleWarningComponent, {class: 'modal-lg modal-center'});
        }
      }
      this.isActive = false;
    }, 10000);
  }

  private refreshToken() {
    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.authService.refreshToken().subscribe(data => {
      if (data) {
        this.authService.storeToken(data);
      }
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    if (!this.isActive) {
      this.isActive = true;
      if (this.modalRef) {
        if (this.authService.isAuthenticated()) {
          this.refreshToken();
        } else {
          this.router.navigate(['login']);
        }
        this.modalRef.hide();
        this.modalRef = null;
      }
    }
  }
}
