import {Component, Input, OnInit} from '@angular/core';
import {MenuItemModel} from '../../models/menu-item.model';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss']
})
export class PageMenuComponent implements OnInit {

  @Input() menuList: MenuItemModel[];

  constructor() {
  }

  ngOnInit() {
  }
}
