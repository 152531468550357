import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from "../../../assets/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationReadEvent: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private httpClient: HttpClient) {
  }

  getNotificationReadEvent(): Observable<string> {
    return this.notificationReadEvent.asObservable().pipe(filter(value => value !== null));
  }

  setNotificationReadEvent(value: string): void {
    this.notificationReadEvent.next(value);
  }

  getNotificationList(page: number, size: number = 20): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', page.toString()).set('size', size.toString());
    return this.httpClient.get(environment.api_root + commonProperties.notifications, {params, observe: 'response'});
  }


  markAllNotificationAsRead(): Observable<any> {
    return this.httpClient.put(environment.api_root + commonProperties.notificationsAction, {action: 'READ'});
  }

  markNotificationAsRead(notificationId: string): Observable<any> {
    return this.httpClient.put(environment.api_root + commonProperties.notificationById.replace(':notificationId', notificationId), {action: 'READ'});
  }
}
