<ng-select
  [items]="fileTemplatesList"
  (change)="change.emit($event)"
  [closeOnSelect]="true"
  [searchable]="true"
  [searchFn]="searchFileTemplates"
  [placeholder]="placeholder"
  [readonly]="readOnly"
  [(ngModel)]="selectedFileTemplate"
  [clearable]="!required"
  #select="ngModel"
  groupBy="type"
  class="ng-select-custom"
>
  <ng-template ng-optgroup-tmp let-item="item">
    {{'company.template.card.types.' + item.type | translate}}
  </ng-template>
  <ng-template ng-label-tmp let-items="item">
    <div *ngIf="items">
      {{ items.templateName }}
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{ item.templateName }}
  </ng-template>
  <ng-template ng-notfound-tmp>
    <div class="ng-option ng-option-disabled ng-star-inserted">
      {{'global.dropdown.not-found'|translate}}
    </div>
  </ng-template>
</ng-select>
<app-feedback
  *ngIf="!selectedFileTemplate && required && (select.dirty || select.touched)"
  [message]="'global.feedback.required'|translate"
></app-feedback>
