import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  @Input() name: string;

  // TODO : Implement profile picture
  employeeImageForm: any;
  file: any;
  savePictureText: any;
  disableButton: Event;

  constructor() {
  }

  ngOnInit() {
  }

  employeeImageFormSubmit() {

  }

  uploadPhoto($event: Event) {

  }
}
