export interface ActivitySheetModel {
  reminderAvailable: boolean;
  id: number;
  accountingMonth: string;
  employeeName: string;
  employeeId: string;
  buyerCompanyName: string;
  buyerId: string;
  supplierCompanyName: string;
  supplierId: string;
  comment: string;
  refusalComment: string;
  purchaseOrderNumber: string;
  status: ActivitySheetStatusEnum;
  type: ActivitySheetTypeEnum;
  empty: boolean;
  submissionDate: string;
  validationDate: string;
  currency: string;
  startDate: string;
  endDate: string;
  dailyRate: number;
  rateUnit: ActivitySheetTypeEnum;
  invoiceGenerated: boolean;
  projectName: string;
  sum: number;
}

export enum ActivitySheetTypeEnum {
  DAYS = 'DAYS',
  HOURS = 'HOURS'
}

export enum ActivitySheetStatusEnum {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}
