<div class="modal-body">

  <i class="fa fa-exclamation-triangle red-icon"></i>

  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="text-align-center">
      <h4>
        {{ 'idle-modal-component.prompt' | translate: {durationInSeconds: durationInSeconds  / 60} }}
      </h4>
    </div>
  </div>
</div>
<div class="progress-container">
  <div class="progress-bar" [style.width.%]="progress"></div>
</div>
