<div class="row">
  <div class="col-sm-auto">
    <div class="circle" [ngStyle]="{'background': color}">
      <i class="font-2xl icon" [ngClass]="[icon]" [ngStyle]="{'color': iconColor}"></i>
    </div>
  </div>
  <div class="col-sm-auto label-position text-muted">
    {{title}}
    <br>
    <strong>{{value}}</strong>
  </div>
</div>
