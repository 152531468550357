<app-page-title [title]="'activity-report.activity-sheet.title'"></app-page-title>

<ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
  <app-spinner [backgroundTransparent]="true"></app-spinner>
</ng-container>

<ng-template #firstCallDoneBlock>
  <div *ngIf="!isListEmpty; else noCompanyBlock" @slideInStagger>
    <!-- basic search and advanced search forms -->
    <div class="row slide-in-animation">
      <div class="col" [ngClass]="{'col-md-6': !isAdvancedSearchDisplayed, 'col-md-12': isAdvancedSearchDisplayed}">
        <div class="text-right" [ngClass]="{'offset-advanced-search': isAdvancedSearchDisplayed}">
          <button class="advanced-search-button" (click)="toggleAdvancedSearch()">
            <u>{{'global.button.advanced-search'|translate}}</u>&nbsp;
            <i class="fa ml-1"
               [ngClass]="{'fa-angle-down': isAdvancedSearchDisplayed, 'fa-angle-left': !isAdvancedSearchDisplayed}"
               aria-hidden="true"></i>
          </button>
        </div>

        <form [formGroup]="advancedSearchForm" (ngSubmit)="advancedSearchFormSubmit()"
              *ngIf="isAdvancedSearchDisplayed; else basicSearchBlock" @fadeIn>
          <div class="row">
            <div class="col form-group">
              <label class="mb-1"
                     for="invoice-number-input">{{'activity-report.activity-sheet.advanced-search.purchase-order.label'|translate}}</label>
              <input type="text" class="form-control" id="invoice-number-input"
                     formControlName="purchaseOrderNumber">
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="purchase-order-number-input">{{'activity-report.activity-sheet.advanced-search.project.label'|translate}}</label>
              <input type="text" class="form-control" id="purchase-order-number-input"
                     formControlName="projectName">
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="buyer-name-input">{{'activity-report.activity-sheet.advanced-search.supplier.label'|translate}}</label>
              <input type="text" class="form-control" id="buyer-name-input"
                     formControlName="supplierCompanyName">
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="supplier-name-input">{{'activity-report.activity-sheet.advanced-search.buyer.label'|translate}}</label>
              <input type="text" class="form-control" id="supplier-name-input"
                     formControlName="buyerCompanyName">
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="supplier-name-input">{{'activity-report.activity-sheet.advanced-search.provider.label'|translate}}</label>
              <input type="text" class="form-control" id="provider-input"
                     formControlName="providerName">
            </div>
            <div class="col form-group">
              <label class="mb-1" for="date-input">{{'activity-report.activity-sheet.advanced-search.period.label'|translate}}</label>
              <div class="d-flex">
                <input type="text" class="form-control" id="date-input" readonly bsDaterangepicker formControlName="period"
                       [bsConfig]="{rangeInputFormat: 'DD/MM/YYYY', showPreviousMonth: true, containerClass: 'theme-red', useUtc: true}">
                <span *ngIf="advancedSearchForm.get('period').value" class="adv-search-date-icon" (click)="clearAdvanceSearchDate('period')">×</span>
              </div>
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="type-input">{{'activity-report.activity-sheet.advanced-search.status.label'|translate}}</label>
              <ng-select [items]="status"
                         [multiple]="true"
                         [closeOnSelect]="false"
                         [hideSelected]="false"
                         [labelForId]="'type-input'"
                         [searchable]="true"
                         formControlName="status"
                         class="ng-select-custom" id="type-input">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/>
                  {{'activity-report.status.' + item | translate | titlecase | statusReadable}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items | slice:0:1">
                    <span class="ng-value-label">{{item | titlecase | statusReadable}}</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{items.length - 1}} {{'global.common.more-ellipsis'|translate}}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>

            <div class="col d-flex align-items-end pl-0">
              <button class="btn btn-outline-primary px-5 mb-3" type="submit" [disabled]="advancedSearchForm.pristine">
                {{'global.button.search'|translate}}
              </button>
            </div>
          </div>
        </form>

        <ng-template #basicSearchBlock>
          <div class="form-group basic-search-group" @fadeIn>
            <input type="text" class="form-control" placeholder="{{'global.common.search-ellipsis'|translate}}"
                   id="search" [value]="basicSearch"
                   (input)="triggerBasicSearch($event.target.value)">
          </div>
        </ng-template>
      </div>

    </div>
    <!-- line for page size and message of number of elements of the page -->
    <div class="row justify-content-between align-items-center mb-3 slide-in-animation">
      <div class="col-md-3 form-inline">
        <select name="result" id="select-result" class="custom-select mr-2"
                [(ngModel)]="size" (change)="changeSize()">
          <option [value]="5">5</option>
          <option [value]="10">10</option>
          <option [value]="20">20</option>
        </select>
        <label for="select-result">{{'global.pages.results-per-page'|translate}}</label>
      </div>
      <div class="col-md-2">
        <span class="float-right">{{'global.pages.showing'|translate}}
          <strong>{{numberOfElements}} {{'global.pages.of'|translate}}  {{totalElements}}</strong> {{'global.pages.entries'|translate}}</span>
      </div>
    </div>

    <div *ngIf="listContent.length > 0; else noResultCompanyBlock" @slideInStagger>
      <div class="row slide-in-animation">
        <div class="col-md-12">
          <table class="table table-hover box-shadow-hover">
            <thead>
            <tr>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('buyer.projectName')"
                  title="Sort project">{{'activity-report.activity-sheet.table.headers.project-name'|translate}}
                <i *ngIf="sort !== 'buyer.projectName'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'buyer.projectName' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'buyer.projectName' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('buyer.companyName')"
                  title="Sort buyer">{{'global.common.buyer'|translate}}
                <i *ngIf="sort !== 'buyer.companyName'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'buyer.companyName' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'buyer.companyName' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('supplier.companyName')"
                  title="Sort supplier">{{'global.common.supplier'|translate}}
                <i *ngIf="sort !== 'supplier.companyName'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'supplier.companyName' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'supplier.companyName' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('supplier.employeeName')"
                  title="Sort supplier number">{{'activity-report.activity-sheet.table.headers.provider'|translate}}
                <i *ngIf="sort !== 'supplier.employeeName'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'supplier.employeeName' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'supplier.employeeName' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('accountingMonth')"
                  title="Sort type">{{'activity-report.activity-sheet.table.headers.period'|translate}}
                <i *ngIf="sort !== 'accountingMonth'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'accountingMonth' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'accountingMonth' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('submissionDate')"
                  title="Sort type">{{'activity-report.activity-sheet.table.headers.submission-date'|translate}}
                <i *ngIf="sort !== 'submissionDate'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'submissionDate' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'submissionDate' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('validationDate')"
                  title="Sort type">{{'activity-report.activity-sheet.table.headers.validation-date'|translate}}
                <i *ngIf="sort !== 'validationDate'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'validationDate' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'validationDate' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('date')" title="Sort date">
                {{'activity-report.activity-sheet.table.headers.quantity'|translate}}
              </th>
              <th scope="col" class="cursor-pointer" (click)="sortColumn('status')"
                  title="Sort period">{{'activity-report.activity-sheet.table.headers.status'|translate}}
                <i *ngIf="sort !== 'status'" class="fa fa-sort float-right"></i>
                <i *ngIf="sort === 'status' && sortDirection === 'asc'"
                   class="fa fa-sort-asc float-right"></i>
                <i *ngIf="sort === 'status' && sortDirection === 'desc'"
                   class="fa fa-sort-desc float-right"></i>
              </th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="company-row" *ngFor="let activitySheet of listContent">
              <td>
                {{activitySheet.projectName}}
                <div class="small text-muted"><span>PO N°</span> {{activitySheet.purchaseOrderNumber}}</div>
              </td>
              <td>{{activitySheet.buyerCompanyName}}</td>
              <td>{{activitySheet.supplierCompanyName}}</td>
              <td>{{activitySheet.employeeName}}</td>
              <td>
                {{activitySheet.accountingMonth | date: 'MMM yyyy'}}
                <div class="small text-muted font-italic">
                  ({{activitySheet.startDate|date: 'dd/MM/yyyy'}} - {{activitySheet.endDate|date: 'dd/MM/yyyy'}})
                </div>
              </td>
              <td class="text-center">
                {{activitySheet.submissionDate ?
                (activitySheet.submissionDate|date: 'dd-MM-yyyy') : ' - '
                }}
              </td>
              <td class="text-center">
                {{activitySheet.validationDate ?
                (activitySheet.validationDate|date: 'dd-MM-yyyy') : ' - '
                }}
              </td>
              <td>{{activitySheet.sum}} {{activitySheet.type | statusReadable}}</td>
              <td>
                <span [ngClass]="['badge', getActivitySheetClass(activitySheet.status)]"
                  [tooltip]="activitySheet.status === 'REFUSED' ? activitySheet.refusalComment : null">
                  {{'activity-report.status.' + activitySheet.status|translate}}
                </span>
              <td>
                <div class="card-header-actions float-right">
                  <a class="card-header-action btn-edit" [tooltip]="'activity-report.activity-sheet.tooltips.remind-users'|translate" *ngIf="activitySheet.reminderAvailable"
                     (click)="sendReminder(activitySheet)" @fadeOut>
                    <i class="fa fa-bell-o" aria-hidden="true"></i>
                  </a>
                  <a class="card-header-action btn-edit" [tooltip]="'activity-report.activity-sheet.tooltips.validate-activity-sheet'|translate" *ngIf="activitySheet.sum === 0 && activitySheet.status === ActivitySheetStatusEnum.SUBMITTED"
                     (click)="confirmTimesheetModeration(ActivitySheetStatusEnum.VALIDATED, activitySheet)">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </a>
                  <a class="card-header-action btn-edit" [tooltip]="'activity-report.activity-sheet.tooltips.cancel-activity-sheet'|translate" *ngIf="displayCancel(activitySheet);"
                     (click)="confirmTimesheetModeration(ActivitySheetStatusEnum.CANCELLED, activitySheet)">
                    <i class="fa fa-ban" aria-hidden="true"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- pagination -->
      <div class="row slide-in-animation" *ngIf="totalElements > size">
        <div class="col-md-12">
          <pagination class="float-right" [totalItems]="totalElements" [maxSize]="5"
                      [boundaryLinks]="true"
                      [ngModel]="page"
                      (pageChanged)="changePage($event)"
                      [itemsPerPage]="size"
                      previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                      lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </div>

    <ng-template #noResultCompanyBlock>
      <div class="text-center my-3" @slideIn>
        <img src="assets/img/result_empty.svg" alt="No results" style="width: 30rem;">
        <h5 class="font-weight-bold mt-3">{{'invoice.client-invoice-list.no-result'|translate}}</h5>
      </div>
    </ng-template>
  </div>

  <ng-template #noCompanyBlock>
    <app-no-content class="company-list-empty" content="{{'invoice.app-no-content.content'|translate}}"
                    iconClassName="fa fa-money" @slideIn>
    </app-no-content>
  </ng-template>
</ng-template>

