import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-password-meter-progress-bar',
  templateUrl: './password-meter-progress-bar.component.html',
  styleUrls: ['./password-meter-progress-bar.component.scss']
})
export class PasswordMeterProgressBarComponent implements OnInit {

  @Input() value: number;
  @Input() color: string;

  constructor() {
  }

  ngOnInit() {
    this.value = 0;
    this.color = '#BBBBBB';
  }
}
