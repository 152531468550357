<div class="sidebar border-right">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['dashboard']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">
          <em class="nav-icon icon-speedometer"
              aria-hidden="true"></em> {{'core.components.navbar.dashboard'|translate}}
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" [routerLink]="['companies']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: false}">
          <em class="nav-icon fa fa-building-o"
              aria-hidden="true"></em> {{'core.components.navbar.companies'|translate}}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['activity-areas']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: false}">
          <em class="nav-icon fa fa-industry"
              aria-hidden="true"></em>{{'core.components.navbar.activity-areas'|translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" [routerLink]="['legal-documents']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: false}">
          <span class="nav-icon material-symbols-outlined">file_present</span>
          {{'core.components.navbar.legal-documents'|translate}}
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" [routerLink]="['requests']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: false}">
          <em class="nav-icon fa fa-suitcase" aria-hidden="true"></em> {{'core.components.navbar.requests'|translate}}
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['contracts']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">
          <em class="nav-icon fa fa-handshake-o"
              aria-hidden="true"></em> {{'core.components.navbar.contracts'|translate}}
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['purchase-orders']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">
          <em class="nav-icon fa fa-file-text-o"
              aria-hidden="true"></em> {{'core.components.navbar.purchase-orders'|translate}}
        </a>
      </li>

      <app-nav-dropdown [headerTitle]="'core.components.navbar.marketplace-title'|translate">
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/marketplace/calls-for-tender']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-newspaper-o"
                aria-hidden="true"></em>{{'core.components.navbar.marketplace.calls-for-tender'|translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/marketplace/leagues']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-institution"
                aria-hidden="true"></em>{{'core.components.navbar.marketplace.leagues'|translate}}</a>
        </li>
      </app-nav-dropdown>

      <app-nav-dropdown [headerTitle]="'core.components.navbar.accounting'|translate">
        <li>
          <app-nav-dropdown isSubHeader="true" [headerTitle]="'core.components.navbar.invoices-title'|translate">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/invoices/supplier']" routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: false}">
                <em class="nav-icon fa fa-newspaper-o" aria-hidden="true"></em>{{'global.common.supplier'|translate}}</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/invoices/client']" routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: false}">
                <em class="nav-icon fa fa-institution" aria-hidden="true"></em>Client</a>
            </li>
          </app-nav-dropdown>
        </li>
        <li>
          <app-nav-dropdown isSubHeader="true" [headerTitle]="'core.components.navbar.credit-notes-title'|translate">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/invoices/supplier-credit-notes']" routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: false}">
                <em class="nav-icon fa fa-newspaper-o" aria-hidden="true"></em>{{'global.common.supplier'|translate}}</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/invoices/client-credit-notes']" routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: false}">
                <em class="nav-icon fa fa-institution" aria-hidden="true"></em>Client</a>
            </li>
          </app-nav-dropdown>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/invoices/manual']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-institution" aria-hidden="true"></em>{{'core.components.navbar.manual'|translate}}</a>
        </li>
      </app-nav-dropdown>

      <app-nav-dropdown [headerTitle]="'core.components.navbar.activity-report.title'|translate">
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/activity-report/activity-sheets']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-newspaper-o"
                aria-hidden="true"></em>{{'core.components.navbar.activity-report.activity-sheet'|translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['fees']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}" [queryParams]="{sort:'status,asc'}">
            <em class="nav-icon fa icon-wallet"
                aria-hidden="true"></em> {{'core.components.navbar.fees'|translate}}
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['on-call']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}" [queryParams]="{sort:'status,asc'}">
            <em class="nav-icon fa icon-doc"
                aria-hidden="true"></em> {{'core.components.navbar.on-call'|translate}}
          </a>
        </li>
      </app-nav-dropdown>

      <app-nav-dropdown [headerTitle]="'Administration'">
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['mailing']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-envelope-o" aria-hidden="true"></em> {{'core.components.navbar.invoices.mails'|translate}}
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
            *ngIf="isRoot()">
          <a class="nav-link" [routerLink]="['configuration']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-cogs" aria-hidden="true"></em> Configuration
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['reports']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-newspaper-o"
                aria-hidden="true"></em>{{'global.common.reports'|translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['users']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-users"
                aria-hidden="true"></em>{{'global.common.users'|translate}}</a>
        </li>
      </app-nav-dropdown>

    </ul>
  </nav>
</div>
