import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TemplateService} from '../../../../../company/services/template.service';
import {TemplateModel} from '../../../template-card/models/template.model';
import {TemplateTypesEnum} from '../template-types-dropdown/models/template-types.enum';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-template-file-templates-dropdown',
  templateUrl: './template-file-templates-dropdown.component.html',
  styleUrls: ['./template-file-templates-dropdown.component.scss']
})
export class TemplateFileTemplatesDropdownComponent implements OnInit {
  @Input() buyerId: string;
  @Output() change = new EventEmitter<TemplateModel>();
  @Input() placeholder: string;
  @Input() templateSignatories: any[] | null;
  @Input() typeFilter: string;
  @Input() selectedFileTemplate: TemplateModel;
  @Input() selectedFileTemplateId: number;
  @Input() archived: boolean = false;
  @Input() readOnly: boolean;
  @Input() customFile: boolean = false;
  @Input() required: boolean = false;
  fileTemplatesList: TemplateModel[];

  constructor(private templateService: TemplateService, private translateService: TranslateService) {}

  ngOnInit() {this.retrieveFileTemplates(); }

  retrieveFileTemplates() {
    this.templateService.retrieveTemplateList(this.buyerId, 0, 20000, 'typeModelCreationOrder,asc', null, null, this.archived).subscribe({
      next: data => {
        if (this.typeFilter) {
          const filterType = this.typeFilter === TemplateTypesEnum.FRAMEWORK_AGREEMENT ? TemplateTypesEnum.APPLICATION_AGREEMENT : TemplateTypesEnum.FRAMEWORK_AGREEMENT;
          this.fileTemplatesList = data.content.filter(item => item.type !== filterType);
        } else {
          this.fileTemplatesList = data.content;
        }

        if (this.selectedFileTemplateId) {
          this.selectedFileTemplate = this.fileTemplatesList.find(item => item.id === this.selectedFileTemplateId.toString());
        }

        if (this.customFile) {
          this.selectedFileTemplate = {id: null, templateName: this.translateService.instant('company.template.card.types.UPLOADED'), type: TemplateTypesEnum.UPLOADED};
          this.fileTemplatesList = [this.selectedFileTemplate, ...this.fileTemplatesList];
        }
      }
    });
  }

  searchFileTemplates(term: string, item: TemplateModel) {
    return item.templateName.toLowerCase().includes(term.toLowerCase());
  }
}
