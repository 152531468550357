import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ServiceSourceEnum} from '../models/service-source.enum';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';
import {
  TemplateGroupDetailsModel,
  TemplateModel,
  TemplatePostResource,
  TemplateResponse, TemplateVariablesModel
} from '../../shared/components/template-card/models/template.model';
import {ContractVersionFileModel, ContractVersionFileResponse} from '../../contract/models/contract-info-model';
import {
  TemplateTypesEnum
} from '../../shared/components/dropdowns/templates/template-types-dropdown/models/template-types.enum';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  contractTemplateApi = environment.api_root + commonProperties.contractTemplateList;
  private selectedTemplate = new BehaviorSubject(null);
  constructor(private httpClient: HttpClient) {}

  retrieveSpecificField(templateId: string): Observable<[]> {
    const url = `${this.contractTemplateApi}/${templateId}/fields`;
    return this.httpClient.get<[]>(url);
  }

  retrieveTemplateList(buyerId: string, page: number, size: number, sort: string, search: string, types: TemplateTypesEnum, archived?: boolean): Observable<TemplateResponse> {
    let params = new HttpParams().set('buyerId', buyerId);
    if (page) {params = params.set('page', page); }
    if (size) {params = params.set('size', size); }
    if (sort) {params = params.set('sort', sort); }
    if (search) {params = params.set('search', search); }
    if (types) {params = params.set('types', types); }
    if (archived !== undefined) {params = params.set('archived', archived); }
    return this.httpClient.get<TemplateResponse>(environment.api_root + commonProperties.contractTemplateList, {params: params});
  }

  updateTemplate(templateId: string, params: TemplateModel): Observable<TemplateModel> {
    return this.httpClient.patch<TemplateModel>(environment.api_root + commonProperties.contractTemplateById.replace(':templateId', templateId), params);
  }

  archiveTemplate(templateId: string) {
    return this.httpClient.delete(environment.api_root + commonProperties.contractTemplateById.replace(':templateId', templateId));
  }

  downloadTemplate(templateId: string, asPdf: boolean = false): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    if (asPdf) {params = params.set('pdf', 'true'); }
    return this.httpClient.get<Blob>(environment.api_root + commonProperties.contractTemplateById.replace(':templateId', templateId),
      {params: params, observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  setSelectedTemplate(selectedValue: {template: any, source: ServiceSourceEnum}) {
    this.selectedTemplate.next(selectedValue);
  }

  postVariablesFile(fileToUpload: File): Observable<TemplateVariablesModel> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.httpClient.post<TemplateVariablesModel>(environment.api_root + commonProperties.contractTemplateFileVariables, formData, {});
  }

  getSelectedTemplate() {
    return this.selectedTemplate.asObservable();
  }

  postTemplate(buyerId: string, templatePostResource: TemplatePostResource): Observable<TemplateModel> {
    const params = new HttpParams().set('buyerId', buyerId);
    return this.httpClient.post<TemplatePostResource>(environment.api_root + commonProperties.contractTemplateList, templatePostResource, {params});
  }

  retrieveTemplateGroupsList(buyerId: string, page: number, size: number, sort: string, search: string, types: TemplateTypesEnum, archived?: boolean): Observable<TemplateResponse> {
    let params = new HttpParams().set('buyerId', buyerId);
    if (page) {params = params.set('page', page); }
    if (size) {params = params.set('size', size); }
    if (sort) {params = params.set('sort', sort); }
    if (search) {params = params.set('search', search); }
    if (types) {params = params.set('types', types); }
    if (archived !== undefined) {params = params.set('archived', archived); }
    return this.httpClient.get<TemplateResponse>(environment.api_root + commonProperties.templateGroupList, {params: params});
  }

  updateTemplateGroup(templateId: string, params: TemplateModel): Observable<TemplateModel> {
    return this.httpClient.patch<TemplateModel>(environment.api_root + commonProperties.templateGroupById.replace(':groupId', templateId), params);
  }

  archiveTemplateGroup(templateId: string) {
    return this.httpClient.delete(environment.api_root + commonProperties.templateGroupById.replace(':groupId', templateId));
  }

  downloadTemplateGroup(templateId: string, asPdf: boolean = false): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    if (asPdf) {params = params.set('pdf', 'true'); }
    return this.httpClient.get<Blob>(environment.api_root + commonProperties.templateGroupByIdTemplate.replace(':groupId', templateId),
      {params: params, observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  postContractTemplate(buyerId: string, templateGroupResource: any): Observable<any> {
    const params = new HttpParams().set('buyerId', buyerId);
    return this.httpClient.post<any>(environment.api_root + commonProperties.templateGroupList, templateGroupResource, {params});
  }

  getTemplateGroupPreview(templateIds: string[]): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    });
    return this.httpClient.post(environment.api_root + commonProperties.templateGroupPreview, templateIds, {headers, responseType: 'blob'});
  }

  getTemplateGroupDetails(templateId: string): Observable<TemplateGroupDetailsModel> {
    return this.httpClient.get<TemplateGroupDetailsModel>(environment.api_root + commonProperties.templateGroupById.replace(':groupId', templateId));
  }

  postContractVersionFile(contractVersionFileModel: ContractVersionFileModel, contractVersion: string, fileId?: string): Observable<ContractVersionFileResponse> {
    let params = new HttpParams();
    if (fileId) {
      params = params.set('fileId', fileId);
    }
    return this.httpClient.post<ContractVersionFileResponse>(environment.api_root + commonProperties.templateContractVersionsFile.replace(':contractVersionId', contractVersion), contractVersionFileModel, {params});
  }

  previewContractVersionFile(contractVersion: string, fileId: string, variables: { [p: string]: string }): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    });
    return this.httpClient.post(environment.api_root + commonProperties.templateContractVersionsPreviewFile.replace(':contractVersionId', contractVersion).replace(':fileId', fileId), variables, {headers, responseType: 'blob'});
  }

  previewContractVersion(contractVersion: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    });
    return this.httpClient.post(environment.api_root + commonProperties.templateContractVersionsPreview.replace(':contractVersionId', contractVersion), {}, {headers, responseType: 'blob'});
  }

  deleteContractVersionFile(contractVersionId: string, contractVersionFileId: string): Observable<void> {
    return this.httpClient.delete<void>(environment.api_root + commonProperties.templateContractVersionsDelete.replace(':contractVersionId', contractVersionId).replace(':contractVersionFileId', contractVersionFileId));
  }

  putContractVersionFile(fileToUpload: File, contractVersionId: string, fileId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.httpClient.put<any>(environment.api_root + commonProperties.templateContractVersionsUploadFile.replace(':contractVersionId', contractVersionId).replace(':fileId', fileId), formData, {});
  }

  getTemplateVariables(templateId: any) {
    return this.httpClient.get<any>(environment.api_root + commonProperties.contractTemplateVariables.replace(':templateId', templateId));
  }

  validateContractVersion(contractVersionId: string): Observable<any> {
    return this.httpClient.post<void>(environment.api_root + commonProperties.templateContractVersionsValidation.replace(':contractVersionId', contractVersionId), {});
  }

  downloadContractVersion(contractVersionId: string, fileId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(environment.api_root + commonProperties.templateContractVersionsDownload.replace(':contractVersionId', contractVersionId).replace(':fileId', fileId), { observe: 'response', responseType: 'blob' as 'json'});
  }
}
