export class AdministrativeManagerModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  constructor(object?: any) {
    if (object) {
      this.firstName = object.firstName;
      this.lastName = object.lastName;
      this.email = object.email;
      this.phoneNumber = object.phoneNumber;
    }
  }
}

export class CompanyCreationBodyModel {
  name: string;
  type: string;
  administrativeManager?: AdministrativeManagerModel;
  administrativeManagerId?: number;
  parentId: number;
  origin: string;
  incorporationNumber: string;

  constructor(object?: any) {
    if (object) {
      this.name = object.name;
      this.type = object.type;
      this.origin = object.origin;
      this.incorporationNumber = object.incorporationNumber;
      if (object.administrativeManagerId) {
        this.administrativeManagerId = object.administrativeManagerId;
      } else {
        this.administrativeManager = new AdministrativeManagerModel(object.administrativeManager);
      }
      this.parentId = object.parentId;
    }
  }
}
