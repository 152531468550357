import {animate, group, keyframes, query, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth-service.service';
import * as _ from 'lodash';
import {BsDropdownDirective} from "ngx-bootstrap/dropdown";
import {EventBusService} from "../../../shared/utils/event-bus.service";
import {fadeInAnimation} from '../../../shared/animation/common.animation';
import {NotificationModel} from '../../../notification/models/notification.model';
import {NotificationWebsocketService} from '../../services/notification-websocket.service';
import {NotificationService} from '../../../notification/services/notification.service';
import {NotificationToastrComponent} from '../../../shared/components/notification-toastr/notification-toastr.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('newNotification', [
      transition(':enter', [
        group([
          useAnimation(fadeInAnimation),
          query('i.fa', [
            animate('.4s .1s', keyframes([
              style({transform: 'rotate(15deg)'}),
              style({transform: 'rotate(-15deg)'}),
              style({transform: 'rotate(15deg)'}),
              style({transform: 'rotate(-15deg)'}),
              style({transform: 'rotate(0)'})
            ]))
          ], {optional: true})
        ])

      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('notificationDropdown', {static: true}) notificationDropdown: BsDropdownDirective;

  username: string;

  notificationList: NotificationModel[] = [];
  totalUnreadNotificationCount: number;
  newUnreadNotificationCount: number;
  newUnreadNotificationId: string[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private notificationWebsocketService: NotificationWebsocketService,
              private notificationService: NotificationService,
              private toastrService: ToastrService,
              private logger: NGXLogger,
              private router: Router,
              private eventBusService: EventBusService) {
  }

  ngOnInit(): void {
    // this.notificationWebsocketService.connect(localStorage.getItem('access_token'));

    this.subscriptions.push(this.authService.getUsername().subscribe(value => {
      if (value) {
        this.username = value;
      }
    }));

    this.subscribeToNotification();

    this.retrieveNotificationList();

    this.subscribeToNotificationReadEvent();
  }

  private subscribeToNotification(): void {
    this.subscriptions.push(this.notificationWebsocketService.getObservable().subscribe({
        next: message => {
          if (message.type === 'SUCCESS') {
            const notification = new NotificationModel(message.message);
            notification.read = false;
            this.logger.info('New notification :', notification);

            this.notificationList.unshift(notification);
            this.notificationList.pop();
            if (!this.notificationDropdown.isOpen) {
              this.newUnreadNotificationCount++;
              this.newUnreadNotificationId.push(notification.id);
            }
            this.totalUnreadNotificationCount++;

            this.displayNotificationToastr(notification);
          }
        },
        error: error => {
          this.logger.error(error.url, '- STATUS :', error.status);
        }
      })
    );
  }

  private displayNotificationToastr(notification: NotificationModel): void {
    const options = _.cloneDeep(this.toastrService.toastrConfig);
    options.toastComponent = NotificationToastrComponent;

    options.toastClass = 'notification-toastr';
    // options.disableTimeOut = true;
    options.tapToDismiss = true;
    options.preventDuplicates = false;
    options.progressBar = true;
    options.progressAnimation = 'decreasing';
    options.timeOut = 5000;

    const notificationToastr = this.toastrService.show(notification.message, notification.title, options);
    notificationToastr.toastRef.componentInstance.origin = notification.serviceName;
    this.subscriptions.push(notificationToastr.toastRef.componentInstance.notificationClicked.subscribe(
      () => {
        this.logger.info('notification clicked');
        this.markNotificationAsRead(notification);
      })
    );
  }

  private subscribeToNotificationReadEvent(): void {
    this.subscriptions.push(this.notificationService.getNotificationReadEvent().subscribe(
      (value: string) => {
        if (value === 'ALL') {
          this.logger.debug('Mark all notification as read');
          this.notificationList.forEach(notification => {
            notification.read = true;
          });
          this.totalUnreadNotificationCount = 0;
          this.newUnreadNotificationCount = 0;
        } else {
          this.totalUnreadNotificationCount -= 1;

          const newUnreadNotificationIndex = this.newUnreadNotificationId.indexOf(value);
          if (newUnreadNotificationIndex !== -1) {
            this.newUnreadNotificationId.splice(newUnreadNotificationIndex, 1);
            this.newUnreadNotificationCount -= 1;
          }

          const notification = this.notificationList.find(n => n.id === value);
          if (notification) {
            this.logger.debug('Mark notification', notification.id, 'as read');
            notification.read = true;
          }
        }
      }
    ));
  }

  private retrieveNotificationList(): void {
    this.subscriptions.push(this.notificationService.getNotificationList(0, 4).subscribe(
      (res: any) => {
        this.logger.info('your notification list is:', res.body.content);
        this.notificationList = res.body.content;
        this.totalUnreadNotificationCount = res.headers.get('unread');
        this.logger.info('unread notifications:', this.totalUnreadNotificationCount);
        this.newUnreadNotificationCount = res.headers.get('unread');
      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  setNewUnreadNotificationCountToZero(): void {
    this.newUnreadNotificationCount = 0;
  }

  markAllNotificationAsRead(): void {
    this.subscriptions.push(this.notificationService.markAllNotificationAsRead().subscribe(
      (res: any) => {
        this.notificationService.setNotificationReadEvent('ALL');
      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  markNotificationAsRead(notification: NotificationModel): void {
    if (!notification.read) {
      this.subscriptions.push(this.notificationService.markNotificationAsRead(notification.id).subscribe(
        (res: any) => {
          this.notificationService.setNotificationReadEvent(notification.id);
        }, error => {
          this.logger.error(error.url, '- STATUS :', error.status);
        }
      ));
    }

    if (notification.url) {
      this.router.navigate([notification.url]);
      this.notificationDropdown.hide();
    }
  }

  getNotificationTimeFromNow(date: string): string {
    if (moment().diff(date, 'days') >= 1) {
      return moment(date).format('DD MMMM YYYY');
    } else {
      return moment(date).fromNow();
    }
  }

  logout(): void {
    this.eventBusService.clearEvents();
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
