import {Component, Input, OnInit} from '@angular/core';
import {MenuItemModel} from "../../models/menu-item.model";

@Component({
  selector: 'vertical-page-menu',
  templateUrl: './vertical-page-menu.component.html',
  styleUrls: ['./vertical-page-menu.component.scss']
})
export class VerticalPageMenuComponent implements OnInit {

  @Input() menuList: MenuItemModel[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
