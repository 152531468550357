import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TemplateModel} from './models/template.model';
import {faArchive, faDownload, faEdit} from '@fortawesome/pro-duotone-svg-icons';
import {ButtonTypeEnum, IspColoursEnum} from '@i-supplier/angular-shared-module';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent {
  @Input() template: TemplateModel;
  @Input() updatable: boolean = true;
  @Input() disableArchive: boolean = false;
  @Input() disabledArchiveTooltip: string;
  @Output() triggerUpdate = new EventEmitter<TemplateModel>();
  @Output() triggerDownload = new EventEmitter<{id: string}>();
  @Output() triggerArchive = new EventEmitter<{id: string}>();
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly ButtonColour = IspColoursEnum;
  protected readonly faDownload = faDownload;
  protected readonly faEdit = faEdit;
  protected readonly faArchive = faArchive;
}
