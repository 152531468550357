import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {

  @Input() content: string;
  @Input() buttonContent: string;
  @Input() link: string;
  @Input() iconClassName: string;

  constructor() {
  }

  ngOnInit() {
  }

}
