<header class="app-header navbar">
  <button class="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button"
          data-toggle="sidebar-show">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand ">
    <img class="navbar-brand-full sidebar-minimizer brand-minimizer" onclick="collapseNavbar()"
         src="assets/brand/logo.svg" alt="CoreUI Logo">
    <img class="navbar-brand-minimized sidebar-minimizer brand-minimizer" onclick="collapseNavbar()"
         src="assets/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo">
  </a>
  <ul class="nav navbar-nav ml-auto">

    <li class="nav-item dropdown" dropdown #notificationDropdown="bs-dropdown" [insideClick]="true" [autoClose]="true"
        (onShown)="setNewUnreadNotificationCountToZero()">
      <button class="nav-link" type="button" dropdownToggle title="Notification">
        <span class="notification">
          <i class="icon-bell notification-icon" aria-hidden="true"></i>
          <span class="notification-number" *ngIf="newUnreadNotificationCount > 0">{{newUnreadNotificationCount}}</span>
        </span>
      </button>
      <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <li class="dropdown-item notification-dropdown-title">
          <i class="fa fa-bell" aria-hidden="true"></i>
          <span class="flex-grow-1">{{'core.components.header.unread-notifications'|translate: {number: totalUnreadNotificationCount} }} &nbsp;</span>
          <button class="btn btn-sm btn-secondary btn-read" title="Mark all as read"
                  (click)="markAllNotificationAsRead()">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </button>
        </li>

        <li class="dropdown-item" *ngFor="let notification of notificationList"
            [class.cursor-pointer]="notification.url || !notification.read"
            (click)="markNotificationAsRead(notification)" @newNotification>
          <div class="d-flex justify-content-start align-items-center notification"
               [class.notification-unread]="!notification.read">
            <div class="icon-container mr-3">
              <ng-container [ngSwitch]="notification.serviceName">
                <i class="fa fa-coffee" aria-hidden="true" *ngSwitchCase="'marketplace'"></i>
                <i class="fa fa-building-o" aria-hidden="true" *ngSwitchCase="'company'"></i>
                <i class="fa fa-suitcase" aria-hidden="true" *ngSwitchCase="'request'"></i>
                <i class="fa fa-handshake-o" aria-hidden="true" *ngSwitchCase="'contract'"></i>
                <i class="fa fa-bell" aria-hidden="true" *ngSwitchDefault></i>
              </ng-container>
            </div>

            <div class="flex-grow-1">
              <h5 class="notification-title mb-1">{{notification.title}}</h5>
              <p class="notification-message mb-0 mr-3" [title]="notification.message">
                {{notification.message | ellipsis: 35}}
              </p>
            </div>
            <div class="align-self-end">
              <span
                class="notification-date float-right">{{getNotificationTimeFromNow(notification.createdDate)}}</span>
            </div>
          </div>
        </li>

        <li>
          <a class="dropdown-item notification-link" [routerLink]="['notification']"
             (click)="notificationDropdown.hide()">
            {{'core.components.header.button.view-notifications'|translate}}
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item dropdown" dropdown>
      <a class="nav-link cursor-pointer" data-toggle="dropdown" role="button" aria-haspopup="true"
         aria-expanded="false" dropdownToggle>
        <img class="img-avatar" [src]="'https://ui-avatars.com/api/?name='+username">
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <span class="dropdown-item">{{username | titlecase}}</span>
        <a class="dropdown-item" (click)="logout()">
          <span class="fa fa-lock"></span> {{'core.components.header.button.logout'|translate}}</a>
      </div>
    </li>
    <li class="nav-item">
      <button class="nav-link btn-link" (click)="logout()" title="Logout">
        <span class="icon-power"></span>
      </button>
    </li>
  </ul>
</header>
