import {AfterViewInit, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {AuthService} from '../../services/auth-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  @ViewChildren(BsDropdownDirective) dropdownList: QueryList<BsDropdownDirective>;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dropdownList.forEach(dropdown => {
        dropdown.show();
        setTimeout(() => {
          dropdown.hide();
        });
      });
    });
  }

  isRoot() {
    return this.authService.isRoot();
  }

}
