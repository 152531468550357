import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-template-signatory-dropdown',
  templateUrl: './template-signatory-dropdown.component.html',
  styleUrls: ['./template-signatory-dropdown.component.scss']
})
export class TemplateSignatoryDropdownComponent {
  @Output() change = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() templateSignatories: any[] | null;
  @Input() required: boolean = false;
  selectedTemplate: string;
  readOnly: boolean;
}
