<div class="row">
  <div class="col">
    <ul class="nav nav-pills">
      <ng-container *ngFor="let item of menuList">
        <li class="nav-item">
          <a [routerLink]="item.link" class="nav-link"
             [routerLinkActiveOptions]="{queryParams: 'ignored'}"
             routerLinkActive="active">{{item.title|translate}}
            <fa-duotone-icon [icon]="item.icon" *ngIf="item.icon"></fa-duotone-icon>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
