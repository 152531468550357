<div class="card box-shadow-hover">
  <div class="card-body">
    <div class="text-align-center">
      <div class="card-title">
        <h5  [tooltip]="template.templateName">
          {{ template.templateName | ellipsis: 27}}
        </h5>
      </div>
      <p class="card-subtitle mb-1">{{ 'company.template.card.types.'+template.type | translate }}</p>
    </div>

    <p class="card-text text-muted">{{template.description}}</p>
  </div>
  <div class="footer-card">
    <p class="card-subtitle creation-details"
       [innerHTML]="('company.template.card.creation-detail'+(template.creatorName?'':'-no-creator')) | translate: {createdDate: (template.createdDate | localizedDate) , creatorName: (template.creatorName)}"
    ></p>
    <hr>
    <div class="d-flex justify-content-between">
      <div class="mt-auto mb-auto">
        <span class="badge badge-gray" *ngIf="template.archived">
          {{ 'company.template.card.archived'|translate }}
        </span>
      </div>
      <div class="button-container float-right">
        <isp-button [type]="ButtonTypeEnum.ICON" [icon]="faDownload" [colour]="ButtonColour.success" [tooltip]="'global.button.download'|translate" (trigger)="triggerDownload.emit({id: template.id})"></isp-button>
        <isp-button [type]="ButtonTypeEnum.ICON" [icon]="faEdit" [colour]="ButtonColour.primary" [tooltip]="'global.button.rename'|translate" (trigger)="triggerUpdate.emit(template)" [disabled]="!updatable"></isp-button>
        <isp-button [type]="ButtonTypeEnum.ICON" [icon]="faArchive" [colour]="ButtonColour.secondary" [tooltip]="disabledArchiveTooltip" (trigger)="triggerArchive.emit({id: template.id})" [disabled]="disableArchive"></isp-button>
      </div>
    </div>
  </div>
</div>
