import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-user-ribbon',
  templateUrl: './user-ribbon.component.html',
  styleUrls: ['./user-ribbon.component.scss']
})
export class UserRibbonComponent implements OnInit {

  text: string;
  private subscriptions: Subscription[] = [];

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translateService.get('shared.components.user-ribbon-ts.ng-on-init').subscribe((data: any) => {
      this.text = data;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
