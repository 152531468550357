import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {AccountValidationComponent} from './account-validation/account-validation.component';
import {AuthenticationRoutingModule} from './authentication-routing.module';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {LoginComponent} from './login/login.component';
import {PasswordMeterProgressBarComponent} from './password-meter/password-meter-progress-bar/password-meter-progress-bar.component';
import {PasswordMeterComponent} from './password-meter/password-meter.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgetPasswordComponent,
    AccountValidationComponent,
    ResetPasswordComponent,
    PasswordMeterComponent,
    PasswordMeterProgressBarComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],

})
export class AuthenticationModule {
}
