import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../core/guards/auth.guard';
import {ActivitySheetsComponent} from "./activity-sheets/activity-sheets.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'activity-sheets',
    pathMatch: 'full'
  },
  {
    path: 'activity-sheets',
    component: ActivitySheetsComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivityReportRoutingModule {
}
