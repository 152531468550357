<div class="container-fluid">
  <div class="row no-gutter">
    <div class="d-none d-md-flex col-md-4 col-lg-8 bg-image"></div>
    <div class="col-md-8 col-lg-4">
      <div class="login d-flex align-items-center py-5">
        <div class="container">

          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto">

              <div class="text-center brand-jumbotron">
                <img src="assets/brand/logo.svg" class="img-fluid isupplier-logo"
                     alt="iSupplier logo"/>
                <h4 class="login-heading mb-4">{{'authentication.forget-password-heading'|translate}}</h4>
              </div>

              <form [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPasswordFormSubmit()"
                    *ngIf="!isSuccess">

                <div class="form-group">
                  <label for="email" hidden>{{'global.common.email'|translate}}</label>
                  <input class="form-control" id="email" type="text" formControlName="email"
                         placeholder="{{'authentication.forget-password.form.email-placeholder'|translate}}"/>
                </div>

                <button class="btn btn-primary btn-block font-weight-bold mb-2" type="submit"
                        [disabled]="!forgetPasswordForm.valid">
                  <em class="fa fa-unlock"></em> {{'authentication.forget-password.form.button.recover-password'|translate}}
                </button>

                <a class="btn btn-link btn-block mb-2" [routerLink]="['/login']">
                  {{'global.button.cancel'|translate}}
                </a>
              </form>

              <div class="text-center" *ngIf="isSuccess">

                <div class="swal2-icon swal2-success swal2-animate-success-icon">
                  <div class="swal2-success-circular-line-left"
                       style="background-color: rgb(255, 255, 255);"></div>
                  <span class="swal2-success-line-tip"></span>
                  <span class="swal2-success-line-long"></span>
                  <div class="swal2-success-ring"></div>
                  <div class="swal2-success-fix"
                       style="background-color: rgb(255, 255, 255);"></div>
                  <div class="swal2-success-circular-line-right"
                       style="background-color: rgb(255, 255, 255);"></div>
                </div>

                <p class="success-message">
                  {{'authentication.forget-password.form.success-message.text-1'|translate}}
                  <strong>{{forgetPasswordForm.value.email}}</strong>
                  {{'authentication.forget-password.form.success-message.text-2'|translate}}
                  <br>
                  {{'authentication.forget-password.form.success-message.text-3'|translate}}
                </p>

                <a class="btn btn-primary btn-block  mb-2" [routerLink]="['/login']">
                  {{'authentication.return-login'|translate}}
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
