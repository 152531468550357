<ng-select
  [items]="templateSignatories"
  (change)="change.emit($event)"
  [multiple]="false"
  [closeOnSelect]="true"
  [searchable]="false"
  [(ngModel)]="selectedTemplate"
  [placeholder]="placeholder"
  [readonly]="readOnly"
  [clearable]="!required"
  #select="ngModel"
  bindValue="value"
  class="ng-select-custom"
>
  <ng-template ng-option-tmp let-item="item">
    {{ 'company.template.signatories.' + item.value | translate }}
  </ng-template>
  <ng-template ng-label-tmp let-items="item">
    <div *ngIf="items">
      {{ 'company.template.signatories.' + items.value | translate }}
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp>
    <div class="ng-option ng-option-disabled ng-star-inserted">
      {{'global.dropdown.not-found'|translate}}
    </div>
  </ng-template>
</ng-select>
<app-feedback
  *ngIf="!selectedTemplate && required && (select.dirty || select.touched)"
  [message]="'global.feedback.required'|translate"
></app-feedback>
